<div class="row">
  <div class="col-lg-6">
    <div class="company-list">
      <label for="company" class="col-form-label custom-label pb-3">Select Company</label>
      <select class="form-select mb-3" aria-label=".form-select-lg example" [(ngModel)]="selectedCompany" (change)="getEquipmentList()">
        <option class="form-option" value="0" selected>All Companies</option>
        <option [value]="item.companyId" class="form-option" *ngFor="let item of companyList">{{ item.name }}</option>
      </select>
    </div>
    <div>
      <label for="company" class="col-form-label custom-label pb-3">Search by Equipment</label>
      <div class="position-relative mb-2">
        <img class="search-icon" [src]="constants.staticImages.searchIcon" alt="search icon" />
        <input
          type="text"
          class="form-control ps-5 pt-1"
          name="leftSearchString"
          aria-describedby="leftSearchString"
          placeholder="Search by Equipment"
          [(ngModel)]="leftSearchString"
          (input)="filterLeftSideList()"
        />
      </div>
    </div>

    <div class="viewport-fit">
      <ng-container *ngFor="let categorywiseList of leftSideEquipmentList">
        <div class="mt-2">
          <p class="category-text">{{ categorywiseList?.category }}</p>
          <div *ngFor="let eqp of categorywiseList?.jobEquipments" class="d-flex justify-content-between border-bottom mt-2 ms-2 pb-2">
            <div class="table-cell">
              <span class="description-font">{{ eqp?.description }}</span>
              <span class="text-truncate highlighted-color"> ({{ eqp?.category }}) </span> <br />
              <span class="text-truncate jobtitle-color pt-1">{{ eqp?.companyName }}</span>
            </div>
            <div class="float-end plus-icon-container me-3">
              <button class="border-0" title="add" (click)="addToRight(eqp)">
                <img [src]="constants.staticImages.plusIconForm" alt="plus icon" />
              </button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="col-lg-6 side-table">
    <label for="company" class="col-form-label custom-label pb-3">Search by Equipment</label>

    <div class="position-relative mb-2">
      <img class="search-icon" [src]="constants.staticImages.searchIcon" alt="search icon" />
      <input
        type="text"
        class="form-control ps-5 pt-1"
        name="rightSearchString"
        aria-describedby="rightSearchString"
        placeholder="Search by Equipment"
        [(ngModel)]="rightSearchString"
        (input)="filterRightSideList()"
      />
    </div>
    <div class="mx-2 mt-4" *ngIf="rightSideEquipmentList?.length">
      <div class="my-2 border-bottom" *ngFor="let equipment of rightSideEquipmentList">
        <div class="d-flex justify-content-between pb-2">
          <div class="table-cell">
            <span class="description-font">{{ equipment?.description }}</span>
            <span class="text-truncate highlighted-color"> ({{ equipment?.category }}) </span> <br />
            <span class="text-truncate jobtitle-color pt-1">{{ equipment?.companyName }}</span>
          </div>
          <div class="float-end plus-icon-container me-3" *ngIf="!isDefaultEquipment(equipment)" (click)="showRemoveEquipmentModal(removeEquipment, equipment)">
            <button class="border-0" title="add">
              <img [src]="constants.staticImages.crossIcon" alt="cross icon" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="py-3 px-2 my-2 border-1 border" *ngIf="origin === 'TimeCard'">
      <strong class="f-14-14">Notes:</strong>
      <span class="ms-1 f-12-12">{{ constants.warningMessage.equipment.detail }}</span>
    </div>

    <div class="row">
      <div class="col-lg-12 mb-5">
        <div class="float-end">
          <button class="btn btn-primary" (click)="updateEquipment()">Update</button>
          <button class="btn btn-outline-secondary ms-2" (click)="closeModal()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #removeEquipment>
  <app-modal title="Remove equipment" [modalRef]="modalRef" [positiveResponse]="confirmRemoveButtonTxt" (onAction)="onConfirmRemove($event)">
    <p class="modal-body">Are you sure you want to remove?</p>
  </app-modal>
</ng-template>
