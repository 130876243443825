import { Injectable } from '@angular/core';
import { JobModuleCompleted } from '@pages/dashboard/models/dashboard.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  initialStatus: JobModuleCompleted = {
    isDeliveriesCompleted: false,
    isEquipmentCompleted: false,
    isHoulryTruckingCompleted: false,
    isObservationCompleted: false,
    isQuantitiesCompleted: false,
    isTimecardCompleted: false,
  };
  status = new BehaviorSubject(this.initialStatus);
  status$: Observable<JobModuleCompleted> = this.status.asObservable();

  updateStatus(status: JobModuleCompleted = this.initialStatus): void {
    this.status.next(status);
  }

  updateSingleStatus(key: keyof JobModuleCompleted, status: boolean): void {
    const _status = {
      ...this.status.value,
      [key]: status,
    };
    this.updateStatus(_status);
  }
}
