import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { API_URL_UTIL, BaseComponent } from '@core/utils';
import { getFormattedDate } from '@core/utils/date-format.utils';
import { ForemanService } from '@pages/deliveries/foreman.service';
import { StatusService } from '@sharedServices/status.service';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs';
import { ApiResponse } from 'src/app/@shared/models';
import { EventTask } from 'src/app/@shared/models/user.modal';
import { SidebarModalService } from 'src/app/@shared/services/sidebar-modal.service';
import { UserDataService } from '../../services/user-data.service';
import { ToasterService } from './../../../@core/services/toaster.service';
import {
  AllCompanies,
  CompaniesListResponse,
  EmployeeListCompanwiseResponse,
  EmployeeListCompanyWise,
  HttpStatus,
  UpdateCrewEquipment,
} from './../../../@shared/models/add-employee.modal';
import { AddEmployeeService } from './../../../pages/time-card/add-employee/add-employee.service';
import { AddCrewEquipmentService } from './add-crew-equipment.service';

@Component({
  selector: 'app-add-crew-equipment',
  templateUrl: './add-crew-equipment.component.html',
  styleUrls: ['./add-crew-equipment.component.scss'],
})
export class AddCrewEquipmentComponent extends BaseComponent implements OnInit {
  selectedJobScheduleId!: string;
  selectedJob!: EventTask;
  removeResponseSession!: EmployeeListCompanyWise;
  modalButtonName = 'Yes';
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  logInUser!: string;
  userSelectedJobId!: string;
  userSelectionEvent!: EventTask;
  selectedDate!: Date;
  maxSizePage = this.constants.addEmployee.addEmployeeMaxPageNo;
  totalEmployee = 0;
  currentEmployeePageNo = 1;
  filterArray: Array<EmployeeListCompanyWise> = [];
  filteredString!: string;
  userAddedEmployee: Array<EmployeeListCompanyWise> = [];
  @Input() selectedEmployee!: Array<EmployeeListCompanyWise>;
  initialUserEmployee: Array<EmployeeListCompanyWise> = [];
  selectedCompany = 0;
  searchString = '';
  @Input() companyList!: Array<AllCompanies>;
  @Input() crewList!: Array<EmployeeListCompanyWise>;
  modalRef!: BsModalRef | undefined;
  @Input() companyId!: string;
  @Input() equipmentId!: string;
  @Input() startDate!: Date;

  constructor(
    private readonly addEmployeeService: AddEmployeeService,
    private readonly userDataService: UserDataService,
    private readonly toasterService: ToasterService,
    private readonly modalService: SidebarModalService,
    private readonly popupService: BsModalService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly foremanService: ForemanService,
    private readonly statusService: StatusService,
    private readonly addCrewEquipmentService: AddCrewEquipmentService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getAllCompanies();
    this.getEmployeeList();
    this.getCurrentDetails();
    this.getRightSideCrew();
  }

  getCurrentDetails(): void {
    this.logInUser = this.userDataService.getLoginUser();
    this.selectedJobScheduleId = this.activatedRoute.parent?.parent?.snapshot.params.jobScheduleId;
    if (this.selectedJobScheduleId) {
      const selectedDate = this.activatedRoute.snapshot.queryParams.date;
      this.selectedDate = moment(selectedDate).toDate();
    }
  }

  getAllCompanies(): void {
    this.addEmployeeService
      .getAllCompanies()
      .pipe(takeUntil(this.destroy$))
      .subscribe((allCompanies: CompaniesListResponse) => {
        this.companyList = allCompanies.data;
      });
  }

  getEmployeeList(): void {
    this.addEmployeeService
      .getEmplyeeList(this.selectedCompany, this.searchString, this.currentEmployeePageNo)
      .pipe(takeUntil(this.destroy$))
      .subscribe((crewDetails: EmployeeListCompanwiseResponse) => {
        this.crewList = crewDetails.data.employeelistCompanwise;
        this.totalEmployee = crewDetails.data?.totalCount;
      });
  }

  addEmployeeInPhase(crew: EmployeeListCompanyWise): void {
    let check = false;
    this.initialUserEmployee.forEach((element) => {
      if (element.employeeId === crew.employeeId) {
        check = true;
      }
    });
    if (!check) {
      crew.jobSchedulePhaseID = '0';
      crew.equipmentCrewMembersId = '';
      crew.worksFor = crew.companyId;
      this.userAddedEmployee.push(crew);
      this.initialUserEmployee.push(crew);
    }
  }

  removeEmployeeInPhase(crew: EmployeeListCompanyWise): void {
    const index = this.userAddedEmployee.indexOf(crew);
    this.userAddedEmployee.splice(index, 1);
    this.initialUserEmployee.splice(index, 1);
  }

  filterData(): void {
    this.userAddedEmployee = this.initialUserEmployee;
    this.userAddedEmployee = this.userAddedEmployee.filter((employee) => {
      return employee.fullName?.toLocaleLowerCase()?.includes(this.filteredString?.toLowerCase());
    });
  }

  pageChanged(event: number): void {
    this.currentEmployeePageNo = event;
    this.getEmployeeList();
  }

  updateEmployee(): void {
    const body: any = {
      equipmentCompanyId: Number(this.companyId),
      equipmentId: this.equipmentId,
      startDate: getFormattedDate(this.startDate),
      equipmentCrewMembers: this.initialUserEmployee,
    };
    this.addCrewEquipmentService
      .add<HttpStatus, UpdateCrewEquipment>(body, this.updateEmployeeUrl())
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.toasterService.success(data.message);
        this.closeModal();
        this.onClose.emit(true);
      });
  }

  closeModal(): void {
    this.modalService.hideModal();
  }

  openModalWithComponent(showModal: TemplateRef<unknown>, data: EmployeeListCompanyWise) {
    this.modalRef = this.popupService.show(showModal);
    this.removeResponseSession = data;
  }

  modalResponse($event: boolean): void {
    if ($event) {
      this.removeEmployeeInPhase(this.removeResponseSession);
    }
  }

  getRightSideCrew(): void {
    this.addCrewEquipmentService
      .get<ApiResponse<any>>(this.getRightSideCrewUrl())
      .pipe(takeUntil(this.destroy$))
      .subscribe((apiResponse) => {
        this.userAddedEmployee = apiResponse.data.crewMembersDetails;
        this.initialUserEmployee = [...this.userAddedEmployee];
      });
  }

  getRightSideCrewUrl(): string {
    return `${API_URL_UTIL.shopManager.EquipmentCrewMembers}?equipmentcompanyId=${this.companyId}&equipmentId=${this.equipmentId}&startDate=${getFormattedDate(this.startDate)}`;
  }

  updateEmployeeUrl(): string {
    return `EquipmentCrewMembers`;
  }
}
