import WebViewer from '@pdftron/webviewer';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Input, Output, EventEmitter, AfterContentInit, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from '@env/environment';
import { BaseComponent } from '@core/utils';
@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent extends BaseComponent implements OnInit, AfterViewInit {
  // Syntax if using Angular 8+
  // true or false depending on code
  @ViewChild('viewer', { static: true }) viewer!: ElementRef;
  @Input() fileBlob!: Blob;
  @Input() fileName!: string;
  @Input() fileURL!: string;
  @Output() eventsTrigger: EventEmitter<any> = new EventEmitter<any>();
  webviewData!: any;
  wvInstance: any;
  Trial!: boolean;
  isVisible = false;
  constructor() {
    super();
  }

  ngDoCheck(): void {}
  ngOnInit() {
    this.wvDocumentLoadedHandler = this.wvDocumentLoadedHandler.bind(this);
    // this.fileURL = 'https://pdftron.s3.amazonaws.com/downloads/pl/webviewer-demo.pdf';
  }
  wvDocumentLoadedHandler(): void {
    // you can access docViewer object for low-level APIs
    const { documentViewer, annotationManager, Annotations } = this.wvInstance.Core;
    // and access classes defined in the WebViewer iframe
    const rectangle = new Annotations.RectangleAnnotation();
    rectangle.PageNumber = 1;
    rectangle.X = 100;
    rectangle.Y = 100;
    rectangle.Width = 250;
    rectangle.Height = 250;
    rectangle.StrokeThickness = 5;
    rectangle.Author = annotationManager.getCurrentUser();
    annotationManager.addAnnotation(rectangle);
    annotationManager.drawAnnotations(rectangle.PageNumber);
    //// see https://www.pdftron.com/api/web/WebViewerInstance.html for the full list of low-level APIs
  }

  ngAfterViewInit(): void {
    this.openFile('blob').then(() => {
      this.listener();
    });
  }

  async openFile(type: string): Promise<void> {
    switch (type) {
      case 'blob':
        // method used hear is promise using them code is for reference
        // {
        //   WebViewer(
        //     {
        //       ...environment.apryseConfigration,
        //     },
        //     this.viewer.nativeElement,
        //   ).then((instance) => {
        //     // `myBlob` is your blob data which can come
        //     // from sources such as a server or the filesystem
        //     instance.UI.loadDocument(this.fileBlob, { filename: 'myfile.pdf' });
        //     const { documentViewer, annotationManager } = instance.Core;
        //     this.webviewData = instance;
        //     // const { documentViewer } = instance.Core;
        //     documentViewer.addEventListener('documentLoaded', () => {
        //       this.eventsTrigger.next({
        //         documentLoad: true,
        //       });
        //     });
        //     // perform document operations
        //     // Add header button that will get file data on click
        //   });
        // }

        {
          let instance = await WebViewer(
            {
              ...environment.apryseConfiguration,
            },
            this.viewer.nativeElement,
          );

          await instance.UI.loadDocument(this.fileBlob, { filename: `${this.fileName}` });
          const { documentViewer, annotationManager } = instance.Core;
          this.webviewData = instance;
          // const { documentViewer } = instance.Core;
        }
        break;
      case 'url':
        {
          WebViewer(
            {
              ...environment.apryseConfiguration,
              initialDoc: this.fileURL,
            },
            this.viewer.nativeElement,
          );
        }
        break;
    }
    this.listener();
  }

  closeDoc() {
    this.webviewData.UI.closeDocument();
  }

  async saveFile(): Promise<Blob> {
    const { documentViewer, annotationManager } = this.webviewData.Core;
    const doc = documentViewer.getDocument();
    const xfdfString = await annotationManager.exportAnnotations();
    const data = await doc.getFileData({
      // saves the document with annotations in it
      xfdfString,
    });
    const arr = new Uint8Array(data);
    const blob = new Blob([arr], { type: 'application/pdf' });
    return blob;
  }

  listener() {
    const { documentViewer } = this.webviewData.Core;
    documentViewer.addEventListener('documentLoaded', () => {
      this.eventsTrigger.next({
        documentLoad: true,
      });
      this.removeTools();
    });
    documentViewer.addEventListener('beforeDocumentLoaded', () => {
      this.eventsTrigger.next({
        beforeDocumentLoaded: true,
      });
    });
  }

  removeDom(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.viewer) {
        this.viewer?.nativeElement?.remove();
      }
      resolve(true);
    });
  }

  removeTools() {
    this.webviewData.UI.setHeaderItems((header: any) => {
      // console.log(this.webviewData.UI);
      this.webviewData.UI.disableNotesPanel();
      this.webviewData.UI.disableElements(['toolbarGroup-Forms', 'toolbarGroup-Edit', 'leftPanelButton', 'toolbarGroup-Insert']);
    });
  }
}
