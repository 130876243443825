import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ButtonModule } from 'primeng/button';
import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import { MessagesModule } from 'primeng/messages';
import { SidebarModule } from 'primeng/sidebar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { DirectivesModule } from 'src/app/@shared/directives/directives.module';
import { MediaViewerModule } from '../modules/media-viewer/media-viewer.module';
import { PipesModule } from '../pipes/pipes.module';
import { AddCostcodeComponent } from './add-costcode/add-costcode/add-costcode.component';
import { AddCrewEquipmentComponent } from './add-crew-equipment/add-crew-equipment.component';
import { AddEquipmentComponent } from './add-equipment/add-equipment.component';
import { AddPhaseComponent } from './add-phase/add-phase.component';
import { AppLoaderComponent } from './app-loader/app-loader.component';
import { SidebarComponent } from './app-sidebar/sidebar/sidebar.component';
import { CommonCancelComponent } from './common-cancel/common-cancel.component';
import { CompanyListDropdownComponent } from './company-list-dropdown/company-list-dropdown.component';
import { CustomNumberInputComponent } from './custom-number-input/custom-number-input.component';
import { EmployeeHoursHistoryComponent } from './employee-hours-histroy/employee-hours-history.component';
import { ErrorHandlerComponent } from './error-handler/error-handler.component';
import { JobDetailsComponent } from './job-details/job-details.component';
import { ModalComponent } from './modal/modal.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { SendEmailComponent } from './send-email/send-email.component';
import { ServerPaginationComponent } from './server-pagination/server-pagination.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';
const COMPONENTS = [
  ErrorHandlerComponent,
  PageHeaderComponent,
  ServerPaginationComponent,
  AppLoaderComponent,
  ModalComponent,
  SidebarComponent,
  AddPhaseComponent,
  AddEquipmentComponent,
  CustomNumberInputComponent,
  UploadFilesComponent,
  AddCostcodeComponent,
  SendEmailComponent,
  AddCrewEquipmentComponent,
  EmployeeHoursHistoryComponent,
  PdfViewerComponent,
  CompanyListDropdownComponent,
  JobDetailsComponent,
  CommonCancelComponent,
];
@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    AccordionModule.forRoot(),
    PaginationModule.forRoot(),
    FormsModule,
    BsDropdownModule,
    SidebarModule,
    ButtonModule,
    DirectivesModule,
    ReactiveFormsModule,
    ChipsModule,
    PipesModule,
    TableModule,
    MediaViewerModule,
    MessagesModule,
    DropdownModule,
    SplitButtonModule,
    BsDatepickerModule,
  ],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class SharedComponentsModule {}
