import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { Constants } from './app/@shared/constants/app.constants';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if (environment.sentryKey) {
  Sentry.init({
    dsn: environment.sentryKey,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments application to monitor its
      // performance, including custom Angular routing instrumentation
      new Sentry.BrowserTracing({
        tracePropagationTargets: Constants.environmentsForErrorTracing,
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // values range from 0 to 1, 0.2 signifies 20% of transactions will be captured.
    tracesSampleRate: 1.0,
    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => console.log(`Bootstrap success`))
  .catch((err) => console.error(err));
