<ng-container>
  <div>
    <p-dropdown
      [options]="companyList"
      [(ngModel)]="defaultCompany"
      optionLabel="name"
      [showClear]="false"
      placeholder="Select a Company"
      appendTo="body"
      [baseZIndex]="9999"
      [filter]="true"
      filterBy="name"
      (ngModelChange)="onSelect($event)"
    >
      <ng-template pTemplate="selectedItem">
        <div *ngIf="defaultCompany">
          <button
            class="d-inline-block circle-color"
            [ngStyle]="{ 'background-color': defaultCompany.companyId !== 0 ? defaultCompany.scheduleColor : '' }"
            *ngIf="companyColor && defaultCompany.companyId !== 0"
          ></button>

          <div class="d-inline-block" [ngClass]="{ 'ps-2': companyColor }">{{ defaultCompany.name }}</div>
        </div>
      </ng-template>

      <ng-template let-company pTemplate="item">
        <div>
          <button class="d-inline-block circle-color" *ngIf="companyColor" [ngStyle]="{ 'background-color': company.companyId !== 0 ? company.scheduleColor : '' }"></button>
          <div class="d-inline-block" [ngClass]="{ 'ps-2': companyColor }">{{ company.name }}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>
</ng-container>
