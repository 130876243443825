import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { BaseComponent } from '@core/utils';
import 'hammerjs';
import { Subject, Subscription, interval, takeUntil } from 'rxjs';
import { KeyBoardKey, ThumbnailSize } from '../models/viewer.model';
@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('element', { read: ElementRef }) elements!: QueryList<ElementRef>;
  @ViewChild('imageContainer', { static: true }) imageContainer!: ElementRef;
  hammerManager!: HammerManager;
  @Input() title = '';
  @Input() enableZoom!: boolean;
  @Input() enableRotate!: boolean;
  @Input() allowThumbnail!: boolean;
  @Input() mediaType = '';
  @Input() fileKey = '';
  @Input() allowThumbnailSize!: boolean;
  @Input() slideShowInterval!: number;
  @Input() isPlaying!: boolean;
  @Input() imageIndex!: number;

  noImage = 'assets/images/no-image.png';
  imageViewerVisibility = false;
  images: any[] = [];
  mediaList: any[] = [];
  imageViewerConfig: any;
  isReady = true;
  thumbnailSize = ThumbnailSize;
  selectedThumbnailSize: any = {};
  eventsSubscription!: Subscription;
  unsubscribe$ = new Subject<void>();
  constructor(private readonly cdf: ChangeDetectorRef) {
    super();
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === KeyBoardKey.RIGHT_ARROW) {
      this.nextPrevElement('next');
    }

    if (event.key === KeyBoardKey.LEFT_ARROW) {
      this.nextPrevElement('prev');
    }
  }

  ngAfterViewInit() {
    if (this.imageContainer.nativeElement !== undefined) {
      this.hammerManager = new Hammer.Manager(this.imageContainer.nativeElement);
      this.hammerManager.add(new Hammer.Swipe({ direction: Hammer.DIRECTION_HORIZONTAL }));
      this.hammerManager.on('swipeleft', this.nextPrevElement.bind(this, 'prev'));
      this.hammerManager.on('swiperight', this.nextPrevElement.bind(this, 'next'));
      this.setTooltipToPluginButtons();
    }
  }

  ngOnInit(): void {
    this.onLoad();
    this.selectedThumbnailSize = ThumbnailSize[0];
    this.slideShow();
  }

  onLoad() {
    this.imageViewerConfig = {
      btnClass: 'default', // The CSS class(es) that will apply to the buttons
      zoomFactor: 0.1, // The amount that the scale will be increased by
      containerBackgroundColor: '#fff', // The color to use for the background. This can provided in hex, or rgb(a).
      wheelZoom: true, // If true, the mouse wheel can be used to zoom in
      allowFullscreen: true, // If true, the fullscreen button will be shown, allowing the user to enter fullscreen mode
      allowKeyboardNavigation: true, // If true, the left / right arrow keys can be used for navigation
      enableTooltip: true,
      rotateRightTooltipLabel: 'Rotate right',
      btnIcons: {
        zoomIn: 'fa fa-plus',
        zoomOut: 'fa fa-minus',
        rotateClockwise: 'fa fa-repeat',
        rotateCounterClockwise: 'fa fa-undo',
      },
      btnShow: {
        zoomIn: this.enableZoom,
        zoomOut: this.enableZoom,
        rotateClockwise: this.enableRotate,
        rotateCounterClockwise: this.enableRotate,
        next: false,
        prev: false,
      },
    };
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  playPauseSlideshow() {
    this.isPlaying = !this.isPlaying;
  }

  onCancel() {
    this.imageViewerVisibility = false;
  }

  nextPrevElement(type: string) {
    this.isReady = false;
    if (type === 'next') {
      if (this.mediaList.length - 1 > this.imageIndex) {
        this.imageIndex++;
      } else {
        this.imageIndex = 0;
      }
    } else {
      if (this.imageIndex === 0) {
        this.imageIndex = this.mediaList.length - 1;
      } else {
        this.imageIndex--;
      }
    }
    this.thumbnailSlideWithActiveMedia();
    this.setTooltipToPluginButtons();
  }

  loadCurrentMedia(i: number) {
    this.isReady = false;
    this.imageIndex = i;
    this.thumbnailSlideWithActiveMedia();
  }

  thumbnailToVideo(str: string) {
    return str.concat('#t=5');
  }

  thumbnailSlideWithActiveMedia() {
    setTimeout(() => {
      this.isReady = true;
      this.cdf.detectChanges();
    }, 0);
    this.elements.forEach((element, ind) => {
      if (this.imageIndex === ind) {
        element.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'end',
        });
      }
    });
  }

  onSelectThumbnailSize(event: any) {
    this.selectedThumbnailSize = this.thumbnailSize.find((s) => s.label === event.value.label);
  }

  slideShow() {
    interval(this.slideShowInterval)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (this.isPlaying) {
          this.imageIndex++;
          if (this.mediaList.length === this.imageIndex) {
            this.imageIndex = 0;
          }
          this.thumbnailSlideWithActiveMedia();
          this.cdf.detectChanges();
          this.setTooltipToPluginButtons();
        }
      });
  }

  setTooltipToPluginButtons() {
    setTimeout(() => {
      const btn = this.imageContainer.nativeElement.querySelectorAll('button');
      const customTooltip = 'custom-tooltip';
      btn.forEach((b: any) => {
        if (b.children[0].classList.contains('fa-plus')) {
          b.setAttribute(customTooltip, 'Zoom in');
        } else if (b.children[0].classList.contains('fa-minus')) {
          b.setAttribute(customTooltip, 'Zoom out');
        } else if (b.children[0].classList.contains('fa-repeat')) {
          b.setAttribute(customTooltip, 'Rotation clockwise');
        } else if (b.children[0].classList.contains('fa-undo')) {
          b.setAttribute(customTooltip, 'Rotation anti clockwise');
        } else if (b.children[0].classList.contains('fa-arrows-alt')) {
          b.setAttribute(customTooltip, 'Full screen');
        }
      });
    }, 0);
    this.cdf.detectChanges();
  }
}
