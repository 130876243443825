import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Constants } from '../../../constants/app.constants';
import { BaseComponent } from './../../../../@core/utils/base.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent extends BaseComponent implements OnInit {
  @Input() modalRef: BsModalRef | undefined;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.modalRef?.onHide?.subscribe((res) => {
      if (res === Constants.keys.ESC) {
        this.onCloseClick();
      }
    });
  }

  onCloseClick(): void {
    this.onClose.emit(true);
    this.modalRef?.hide();
  }
}
