import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RefreshTokenService } from '@core/services';
import { getItem, removeItem, StorageItem } from '@core/utils';
import { API_URL_UTIL } from '@core/utils/api-url.utils';
import { ROUTER_UTILS } from '@core/utils/router.utils';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { ServerMessage } from 'src/app/@shared/models';
import { Account, ForgotPasswordFinishRequestParams, ForgotPasswordInitRequestParams, LoginEmailPasswordParams, LoginResponse } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn$ = new BehaviorSubject<boolean>(!!getItem(StorageItem.AuthToken));
  currentUser!: Account;

  constructor(private readonly httpClient: HttpClient, private readonly router: Router, private readonly refreshTokenService: RefreshTokenService) {}

  get isLoggedIn(): boolean {
    return this.isLoggedIn$.getValue();
  }

  loginWithEmailPassword(loginEmailPasswordParams: LoginEmailPasswordParams): Observable<LoginResponse> {
    return this.httpClient.post<LoginResponse>(API_URL_UTIL.account.loginWithEmailPassword, loginEmailPasswordParams).pipe(
      map((res) => {
        this.refreshTokenService.storeAuthTokens(res);
        this.refreshTokenService.startRefreshTokenTimer(res.access_token);
        this.isLoggedIn$.next(true);
        return res;
      }),
    );
  }

  onForgotPasswordInit(params: ForgotPasswordInitRequestParams): Observable<ServerMessage> {
    return this.httpClient.post<ServerMessage>(API_URL_UTIL.account.forgotPasswordInit, params);
  }

  onForgotPasswordFinish(params: ForgotPasswordFinishRequestParams): Observable<void> {
    return this.httpClient.post<void>(API_URL_UTIL.account.forgotPasswordFinish, params);
  }

  /**
   * @param fromDb if fromDb is true, it will always fetch user info from the API request, else it will return current user from cache
   * @returns current logged in user
   */
  getCurrentUser(fromDb = false): Observable<Account | null> {
    if (fromDb || !this.currentUser?.id) {
      return this.httpClient.get<Account>(API_URL_UTIL.account.root).pipe(
        map((res) => {
          this.currentUser = res;
          return res;
        }),
        catchError((err) => {
          this.logOut();
          return of(null);
        }),
      );
    }
    return of(this.currentUser);
  }

  logOut(): void {
    removeItem(StorageItem.AuthToken);
    this.isLoggedIn$.next(false);
    this.refreshTokenService.stopRefreshTokenTimer();
    this.router.navigate([ROUTER_UTILS.config.auth.root, ROUTER_UTILS.config.auth.login]);
  }
}
