import { ChangeDetectorRef, Directive, ElementRef, Input, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { environment } from '@env/environment';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { ImageExtension, ViewerType } from './models/viewer.model';

@Directive({
  selector: '[appMediaViewer]',
})
export class MediaViewerDirective implements OnInit {
  @Input() viewItems: any[] = [];
  @Input() title = 'Media Viewer';
  @Input() enableZoom = true;
  @Input() enableRotate = true;
  @Input() mediaType = '';
  @Input() fileKey = 'path';
  @Input() allowThumbnail = true;
  @Input() urlType = '';
  @Input() allowThumbnailSize = true;
  @Input() slideShowInterval = 3000;
  @Input() isPlaying = true;
  @Input() imageIndex = 0;

  enableMediaViewer = environment.enableMediaViewer;
  noImage = 'assets/images/no-image.png';
  imageExtensions = ImageExtension;
  displayViewer!: boolean;
  shouldNotShowViewer = true;

  constructor(private readonly elementRef: ElementRef, private readonly renderer: Renderer2, public viewContainerRef: ViewContainerRef, private readonly cdf: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.onLoadMediaViewer();
  }

  onLoadMediaViewer() {
    if (this.enableMediaViewer) {
      this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => {
        let url = '';
        let type = '';
        const imgViewerComponent = this.viewContainerRef.createComponent(ImageViewerComponent);
        this.cdf.markForCheck();
        imgViewerComponent.instance.imageViewerVisibility = true;
        imgViewerComponent.instance.title = this.title;
        imgViewerComponent.instance.enableZoom = this.enableZoom;
        imgViewerComponent.instance.enableRotate = this.enableRotate;
        imgViewerComponent.instance.fileKey = this.fileKey;
        imgViewerComponent.instance.mediaType = this.mediaType;
        imgViewerComponent.instance.allowThumbnail = this.allowThumbnail;
        imgViewerComponent.instance.allowThumbnailSize = this.allowThumbnailSize;
        imgViewerComponent.instance.slideShowInterval = this.slideShowInterval;
        imgViewerComponent.instance.isPlaying = this.isPlaying;
        imgViewerComponent.instance.imageIndex = this.imageIndex;

        if (this.viewItems.length) {
          this.viewItems.forEach((i) => {
            if (i['base64']) {
              url = i['base64'] ? i['base64'] : this.noImage;
            } else {
              url = i[this.fileKey] ? i[this.fileKey] : this.noImage;
            }
            const element = {
              [this.fileKey]: url,
              mediaType: this.imageExtensionMatched(url, i.isVideo),
              thumbnail: i['thumbnail'],
              fileName: i['originalFilename'],
            };
            imgViewerComponent.instance.images.push(url);
            imgViewerComponent.instance.mediaList.push(element);
          });
        } else {
          const url = event.currentTarget['src'] ? event.currentTarget['src'] : this.noImage;
          const i = {
            [this.fileKey]: url,
            mediaType: this.imageExtensionMatched(url, this.urlType),
            thumbnail: event.currentTarget['thumbnail'],
            fileName: event.currentTarget['originalFilename'],
          };
          imgViewerComponent.instance.images.push(url);
          imgViewerComponent.instance.mediaList.push(i);
        }
      });
    }
  }

  imageExtensionMatched(url: string, urlType?: string) {
    const str = url?.split(',')[0];
    let isImage;
    // Get base64 image extension
    if (str && str.split(';')[1] === 'base64') {
      if (this.imageExtensions.includes(url?.split(',')[0].split(';')[0].split('/')[1].toLowerCase())) {
        isImage = ViewerType.IMAGE;
      }
    }
    if (urlType) {
      return !urlType ? ViewerType.IMAGE : ViewerType.VIDEO;
    } else {
      // Get string url image extension
      const ext = url?.split(/[#?]/)[0]?.split('.')?.pop()?.trim();
      return (ext && this.imageExtensions.includes(ext.toLowerCase())) || isImage ? ViewerType.IMAGE : ViewerType.VIDEO;
    }
  }
}
