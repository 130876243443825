<div class="mx-auto">
  <p>
    To send email press <strong> Enter <span class="size"> &#9166; </span> </strong> or separate by <strong> comma <span class="size"> &#44; </span> </strong>
  </p>
  <label for="email" class="label mb-3">Email Address</label>
  <p-chips [(ngModel)]="email" [separator]="separator" [allowDuplicate]="allowDuplicate" (ngModelChange)="updateEmail()" [pattern]="emailPattern">
    <ng-template let-item pTemplate="item">
      <span type="email" class="p-inputted mt-1">{{ item }}</span>
    </ng-template>
  </p-chips>
</div>
