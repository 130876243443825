import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FooterModule } from '../footer/footer.module';
import { HeaderModule } from '../header/header.module';
import { AuthorizedLayoutComponent } from './authorized-layout.component';

@NgModule({
  declarations: [AuthorizedLayoutComponent],
  imports: [CommonModule, HeaderModule, FooterModule],
  exports: [AuthorizedLayoutComponent],
})
export class AuthorizedLayoutModule {}
