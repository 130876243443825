import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL_UTIL, setItem, StorageItem } from '@core/utils';
import { LoginResponse } from '@pages/auth/models';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RefreshTokenService {
  private refreshTokenTimeout!: NodeJS.Timeout;

  constructor(private readonly httpClient: HttpClient) {}

  startRefreshTokenTimer(accessToken: string) {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(accessToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  refreshToken(): Observable<LoginResponse> {
    return this.httpClient.post<LoginResponse>(API_URL_UTIL.account.refreshAccessToken, {}).pipe(
      map((response) => {
        this.storeAuthTokens(response);
        this.startRefreshTokenTimer(response.access_token);
        return response;
      }),
    );
  }

  storeAuthTokens(loginResponse: LoginResponse) {
    setItem(StorageItem.AuthToken, loginResponse.access_token);
    setItem(StorageItem.RefreshToken, loginResponse.refresh_token);
  }
}
