import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { API_URL_UTIL } from '@core/utils';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs';
import { AddCostCodeResponse, ExistingCostCodes, NewCostCode } from 'src/app/@shared/models/add-costCode.modal';
import { HttpStatus } from 'src/app/@shared/models/add-employee.modal';
import { getFormattedDate } from '../../../../@core/utils/date-format.utils';
import { UserDataService } from '../../../services/user-data.service';
import { ToasterService } from './../../../../@core/services/toaster.service';
import { BaseComponent } from './../../../../@core/utils/base.component';
import { SaveCostCode } from './../../../models/add-costCode.modal';
import { ApiResponse } from './../../../models/common.model';
import { SidebarModalService } from './../../../services/sidebar-modal.service';
import { AddCostCodeService } from './../add-costcode.service';

@Component({
  selector: 'app-add-costcode',
  templateUrl: './add-costcode.component.html',
  styleUrls: ['./add-costcode.component.scss'],
})
export class AddCostcodeComponent extends BaseComponent implements OnInit {
  patchCompanyId!: number;
  user!: string;
  searchString1 = '';
  searchString2 = '';
  costCodeToRemove!: ExistingCostCodes;
  costCodeListLeft: Array<NewCostCode> = [];
  costCodeListRight: Array<NewCostCode> = [];
  currentCostCodeList: Array<NewCostCode> = [];
  _currentCostCodeLeftList: Array<NewCostCode> = [];
  _currentCostCodeRightList: Array<NewCostCode> = [];
  modalRef!: BsModalRef | undefined;
  @Input() jobScheduleId!: string;
  @Input() jobScheduleDate!: Date;
  @Input() companyId!: string;
  @Input() equipmentId!: string;
  @Input() startDate!: Date;
  @Output() onModalClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private readonly AddCostCodeService: AddCostCodeService,
    private readonly userDataService: UserDataService,
    private readonly toasterService: ToasterService,
    private readonly sidebarModalService: SidebarModalService,
    private readonly modalService: BsModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.user = this.userDataService.getLoginUser();
    this.getCostCodeList();
  }

  getCostCodeList(): void {
    this.AddCostCodeService.get<ApiResponse<AddCostCodeResponse>>(this.getCostCodeUrl())
      .pipe(takeUntil(this.destroy$))
      .subscribe((costCodeResponse) => {
        this.costCodeListLeft = costCodeResponse.data.newCostCodes;
        this.costCodeListRight = costCodeResponse.data.existingCostCodes;
        this._currentCostCodeLeftList = costCodeResponse.data.newCostCodes;
        this._currentCostCodeRightList = costCodeResponse.data.existingCostCodes;
      });
  }

  updateCostCode(): void {
    const body: any = {
      equipmentCompanyID: Number(this.companyId),
      equipmentID: this.equipmentId,
      startDate: getFormattedDate(this.startDate),
      equipmentCostCodes: [...this._currentCostCodeRightList],
    };
    this.AddCostCodeService.add<HttpStatus, SaveCostCode>(body, this.updateCostCodeUrl())
      .pipe(takeUntil(this.destroy$))
      .subscribe((saveResponse) => {
        this.toasterService.success(saveResponse?.message);
        this.closeModal();
        this.updateClose();
      });
  }

  filterLeftCostCode(): void {
    this.costCodeListLeft = [...this._currentCostCodeLeftList]
      .filter((costCode) => {
        return costCode.costCodeDescription?.toLocaleLowerCase()?.includes(this.searchString1?.toLowerCase());
      })
      ?.slice();
  }

  filterRightCostCode(): void {
    this.costCodeListRight = [...this._currentCostCodeRightList]
      .filter((costCode) => {
        return costCode.costCodeDescription?.toLocaleLowerCase()?.includes(this.searchString2?.toLowerCase());
      })
      ?.slice()
      ?.reverse();
  }

  addCostCodeToCurrentList(costCode: NewCostCode): void {
    const costCodeCheck = { ...costCode };
    costCodeCheck.equipmentCostCodesID = '';
    let check = false;
    this._currentCostCodeRightList?.forEach((element) => {
      if (element.costCode === costCodeCheck.costCode) {
        check = true;
      }
    });
    if (!check) {
      this._currentCostCodeRightList.push(costCodeCheck);
      this.filterLeftCostCode();
    }
  }

  showRemoveCostCodeFromCurrentListDialog(template: TemplateRef<unknown>, data: NewCostCode): void {
    this.modalRef = this.modalService.show(template);
    this.costCodeToRemove = data;
  }

  removeCostCodeFromCurrentList(selection: boolean): void {
    if (selection) {
      let index = this.costCodeListRight.findIndex((i) => i?.costCode === this.costCodeToRemove?.costCode);
      if (index >= 0) {
        this.costCodeListRight.splice(index, 1);
      }
      index = this._currentCostCodeRightList.findIndex((i) => i?.costCode === this.costCodeToRemove?.costCode);
      if (index >= 0) {
        this._currentCostCodeRightList.splice(index, 1);
      }
    }
  }

  closeModal(): void {
    this.sidebarModalService.hideModal();
  }

  updateClose(): void {
    this.onModalClose.emit(true);
  }

  getCostCodeUrl(): string {
    return `${API_URL_UTIL.shopManager.costCode}?companyId=${this.companyId}&equipmentId=${this.equipmentId}&startDate=${getFormattedDate(this.startDate)}`;
  }

  updateCostCodeUrl(): string {
    return `${API_URL_UTIL.shopManager.costCode}`;
  }
}
