<span class="sidebar">
  <div class="modal-header">
    <h4 class="modal-title" id="sidebarModalHeader">
      <ng-content select="[modalHeader]"></ng-content>
    </h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCloseClick()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-content select="[modalBody]"></ng-content>
  </div>
</span>
