import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from '@core/services';
import { AuthService } from '@pages/auth/services/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ServerMessage } from '../../@shared/models';
import { ToasterService } from '../services/toaster.service';
import { AppLoaderService } from './../../@shared/services/app-loader.service';
@Injectable({ providedIn: 'root' })
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly authService: AuthService,
    private readonly commonService: CommonService,
    private readonly toasterService: ToasterService,
    private readonly appLoaderService: AppLoaderService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.commonService.isApiCallInProgress$.next(true);
    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          this.commonService.isApiCallInProgress$.next(false);
          this.appLoaderService.toggleLoader(false);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.commonService.isApiCallInProgress$.next(false);
        const errorResponse: ServerMessage = error.error;
        this.toasterService.error(errorResponse.message);
        this.appLoaderService.toggleLoader(false);

        if ([401].includes(error.status)) {
          this.authService.logOut();
          return throwError(() => error);
        } else if (error.status === 500) {
          console.error(error);
          return throwError(() => error);
        } else {
          return throwError(() => error);
        }
      }),
    );
  }
}
