import { Injectable, OnDestroy, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Injectable({
  providedIn: 'root',
})
export class SidebarModalService extends BsModalService implements OnDestroy {
  modalRefs: BsModalRef[] = [];

  showModal(content: string | TemplateRef<any> | any, config?: ModalOptions): BsModalRef {
    const modal = this.show(content, config);
    this.modalRefs.push(modal);
    return modal;
  }

  ngOnDestroy(): void {
    this.modalRefs = [];
  }

  hideModal(id?: string | number | undefined): void {
    this.hide(id);
  }
}
