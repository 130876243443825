import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '@core/services';
import { API_URL_UTIL, BaseComponent, ROUTER_UTILS } from '@core/utils';
import { getFormattedDate } from '@core/utils/date-format.utils';
import { FullCalendarService } from '@pages/Admin/admin-navigation/admin-navigation/full-calendar/full-calendar.service';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs';
import { ApiResponse } from '../../models';
import { HttpStatus } from '../../models/add-employee.modal';
import { DetailPageActionButtonResponse, DetailPagedropdownOptions, SchedulerData, UpdateScheduleNote } from '../../models/admin.modal';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss'],
})
export class JobDetailsComponent extends BaseComponent implements OnInit {
  constructor(
    private readonly fullCalendarService: FullCalendarService,
    private readonly activeRoutes: ActivatedRoute,
    private readonly routes: Router,
    private readonly toasterService: ToasterService,
    private readonly popupService: BsModalService,
  ) {
    super();
  }
  @ViewChild('confirmationCancelPopup') confirmationCancelPopup!: TemplateRef<unknown>;
  @ViewChild('deleteConfirmation') deleteConfirmation!: TemplateRef<unknown>;
  @ViewChild('openPenel') openPenel!: TemplateRef<unknown>;

  jobScheduleDetail!: SchedulerData;
  jobScheduleId!: string;
  notes = '';
  isDetailPage = false;
  dropdownOptions: DetailPagedropdownOptions[] = [];
  inspectorCalled = false;
  inspectorNeeded = false;
  rainedOut = false;
  modalButtonName = 'Yes';
  modalRef!: BsModalRef | undefined;
  currentActionEvent!: string;
  cancelConfirmationPopupTitle!: string;
  companyId!: number;
  dateToCopy = new Date();
  copyJobDropdownOptions: DetailPagedropdownOptions[] = [];
  workForce!: boolean;
  minDate = new Date();

  ngOnInit(): void {
    this.setJobScheduleId();
    this.getJobScheduleDetails();
    this.setCopyJobDropdownOptions();
  }

  setJobScheduleId(): void {
    if (this.activeRoutes?.snapshot?.queryParams?.jobScheduleId) {
      this.jobScheduleId = this.activeRoutes.snapshot.queryParams.jobScheduleId;
      this.companyId = this.activeRoutes.snapshot.queryParams.companyId;
      this.isDetailPage = this.activeRoutes?.snapshot.url[0].path === 'jobDetails';
    } else {
      this.navigateToJobSchedulePage();
    }
  }

  getJobScheduleDetails(): void {
    this.fullCalendarService
      .get<ApiResponse<SchedulerData>>(this.requestBody())
      .pipe(takeUntil(this.destroy$))
      .subscribe((apiResponse) => {
        this.jobScheduleDetail = apiResponse.data;
        if (!this.isDetailPage && apiResponse.data?.notes) {
          this.notes = apiResponse.data?.notes;
        }
        this.setDropdownOptions();
      });
  }

  requestBody(): string {
    return `${API_URL_UTIL.foreman.admin.jobscheduledetailsInScheduler}?JobScheduleId=${this.jobScheduleId}`;
  }

  formatStartDate(date: Date): string {
    return date ? getFormattedDate(moment(date).toDate()) : '';
  }

  submitJobSchedule(): void {
    this.fullCalendarService
      .add<HttpStatus, UpdateScheduleNote>(this.scheduleRequestBody(), API_URL_UTIL.foreman.admin.submitJobSchedule)
      .pipe(takeUntil(this.destroy$))
      .subscribe((saveApiResponse) => {
        this.toasterService.success(saveApiResponse?.message);
        this.routes.navigate([ROUTER_UTILS.config.navigation.foreman, ROUTER_UTILS.config.admin.root, this.path.navigation.scheduler], {
          queryParams: { companyId: this.companyId },
          queryParamsHandling: 'merge',
        });
      });
  }

  scheduleRequestBody(): UpdateScheduleNote {
    return {
      notes: this.notes,
      jobScheduleId: this.jobScheduleId,
    };
  }

  closeJobDetail(): void {
    this.routes.navigate([ROUTER_UTILS.config.navigation.foreman, ROUTER_UTILS.config.admin.root, this.path.navigation.scheduler], {
      queryParams: { companyId: this.companyId },
      queryParamsHandling: 'merge',
    });
  }

  setDropdownOptions(): void {
    if (this.isDetailPage) {
      this.dropdownOptions = [
        {
          label: (this.jobScheduleDetail?.inspectorCalled ? this.constants.jobDetail.unmark : '') + ' Inspector Call Made',
          icon: 'pi pi-mobile',
          command: () => {
            this.commonApiForInspectorCall('InspectorCalled');
          },
        },
        {
          label: (this.jobScheduleDetail?.inspectorNeeded ? this.constants.jobDetail.unmark : '') + ' Inspector Call Needed',
          icon: 'pi pi-phone',
          command: () => {
            this.commonApiForInspectorCall('InspectorNeeded');
          },
        },
        {
          label: (this.jobScheduleDetail?.rainedOut ? this.constants.jobDetail.unmark : this.constants.jobDetail.mark) + ' as Weather Cancellation',
          icon: 'pi pi-map-marker',
          command: () => {
            this.commonApiForInspectorCall('RainedOut');
          },
        },
        {
          label: 'Copy Job',
          icon: 'pi pi-copy',
          command: () => {
            this.openModalForCopyJob();
          },
        },
        {
          label: this.jobScheduleDetail?.jobScheduleStatusId === 4 ? this.constants.jobDetail.unCancelJob : this.constants.jobDetail.cancelJob,
          icon: this.jobScheduleDetail?.jobScheduleStatusId === 4 ? 'pi pi-refresh' : 'pi pi-times',
          command: () => {
            this.openCancelConfirmationModal(this.jobScheduleDetail?.jobScheduleStatusId === 4 ? this.constants.jobDetail.unCancelText : this.constants.jobDetail.cancelText);
          },
        },
        {
          label: 'Delete Job',
          icon: 'pi pi-trash',
          command: () => {
            this.openDeleteConfirmationModal();
          },
          disabled: !this.jobScheduleDetail?.isJobDeletable,
        },
      ];
    } else {
      this.dropdownOptions = [
        {
          label: 'Copy Job',
          icon: 'pi pi-copy',
          command: () => {
            this.openModalForCopyJob();
          },
        },
        {
          label: 'Delete Job',
          icon: 'pi pi-trash',
          command: () => {
            this.openDeleteConfirmationModal();
          },
          disabled: !this.jobScheduleDetail?.isJobDeletable,
        },
      ];
    }
  }

  commonApiForInspectorCall(type: string): void {
    this.fullCalendarService
      .add<HttpStatus, DetailPageActionButtonResponse>(this.apiRequestBody(type), API_URL_UTIL.foreman.admin.jobScheduleActions)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.toasterService.success(response?.message);
        this.getJobScheduleDetails();
      });
  }

  apiRequestBody(type: string): DetailPageActionButtonResponse {
    return {
      jobScheduleId: this.jobScheduleId,
      inspectorCalled: type === 'InspectorCalled' ? !this.jobScheduleDetail?.inspectorCalled : this.jobScheduleDetail?.inspectorCalled,
      inspectorNeeded: type === 'InspectorNeeded' ? !this.jobScheduleDetail?.inspectorNeeded : this.jobScheduleDetail?.inspectorNeeded,
      rainedOut: type === 'RainedOut' ? !this.jobScheduleDetail?.rainedOut : this.jobScheduleDetail?.rainedOut,
      jobScheduleStatusId: this.getJobScheduleStatusId(),
      previousStatusId: this.getPreviousStatusId(),
    };
  }

  getJobScheduleStatusId(): number {
    let statusId = 0;
    if (this.currentActionEvent === this.constants.jobDetail.cancelText) {
      statusId = 4;
    } else if (this.currentActionEvent === this.constants.jobDetail.unCancelText) {
      statusId = this.jobScheduleDetail?.previousJobScheduleStatusId;
    } else {
      statusId = this.jobScheduleDetail?.jobScheduleStatusId;
    }

    return statusId;
  }

  getPreviousStatusId(): number {
    let statusId = 0;
    if (this.currentActionEvent === this.constants.jobDetail.cancelText) {
      statusId = this.jobScheduleDetail?.jobScheduleStatusId;
    } else if (this.currentActionEvent === this.constants.jobDetail.unCancelText) {
      statusId = this.jobScheduleDetail?.jobScheduleStatusId;
    } else {
      statusId = this.jobScheduleDetail?.previousJobScheduleStatusId;
    }

    return statusId;
  }

  openCancelConfirmationModal(type: string): void {
    this.modalRef = this.popupService.show(this.confirmationCancelPopup);
    this.currentActionEvent = type;
    this.cancelConfirmationPopupTitle = type === this.constants.jobDetail.cancelText ? this.constants.jobDetail.cancelJob : this.constants.jobDetail.unCancelJob;
  }

  cancelModalResponse($event: boolean): void {
    if ($event) {
      this.commonApiForInspectorCall(this.currentActionEvent);
    }
  }

  deleteScheduleJob(): void {
    this.fullCalendarService
      .delete('', this.getDelelteJobApiEndpoint())
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.toasterService.success(res?.message);
        this.navigateToJobSchedulePage();
      });
  }

  getDelelteJobApiEndpoint(): string {
    return `${API_URL_UTIL.foreman.admin.jobs}/${this.jobScheduleId}/${API_URL_UTIL.foreman.admin.deleteJobSchedule}`;
  }

  navigateToJobSchedulePage(): void {
    this.routes.navigate([ROUTER_UTILS.config.navigation.foreman, ROUTER_UTILS.config.admin.root, this.path.navigation.scheduler], {
      queryParams: { companyId: this.companyId },
      queryParamsHandling: 'merge',
    });
  }

  deleteModalResponse($event: boolean): void {
    if ($event) {
      this.deleteScheduleJob();
    }
  }

  openDeleteConfirmationModal(): void {
    this.modalRef = this.popupService.show(this.deleteConfirmation);
  }

  gotoWizard(newScheduleId?: string): void {
    this.routes.navigate([this.path.navigation.foreman, this.path.navigation.admin, this.path.admin.wizard, this.path.admin.workforce], {
      queryParams: { jobScheduleId: newScheduleId ? newScheduleId : this.jobScheduleId },
      queryParamsHandling: 'merge',
    });
  }

  openModalForCopyJob(): void {
    this.dateToCopy = new Date();
    this.modalRef = this.popupService.show(this.openPenel);
  }

  calenderDateChange(value: Date): void {
    this.dateToCopy = value;
  }

  setCopyJobDropdownOptions(): void {
    this.copyJobDropdownOptions = [
      {
        label: 'with workforce',
        command: () => {
          this.copyScheduleJobApiCall(false);
        },
      },
      {
        label: 'w/o workforce',
        command: () => {
          this.copyScheduleJobApiCall(true);
        },
      },
    ];
  }

  copyScheduleJobApiCall(workForce: boolean): void {
    this.workForce = workForce;
    this.fullCalendarService
      .add<ApiResponse<any>, any>('', this.copyJobApiEndpoint())
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          if (this.workForce) {
            this.gotoWizard(response.data);
          } else {
            this.toasterService.success(response?.message);
            this.navigateToJobSchedulePage();
          }
          this.closeScheduleCardModal();
        },
        error: () => {
          this.closeScheduleCardModal();
        },
      });
  }

  copyJobApiEndpoint(): string {
    return `${API_URL_UTIL.foreman.admin.copyScheduleJob}?StartDate=${getFormattedDate(this.dateToCopy)}&JobScheduleId=${this.jobScheduleId}&WithOutWorkForce=${this.workForce}`;
  }

  closeScheduleCardModal(): void {
    this.modalRef?.hide();
  }
}
