import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { SeoService, SplashScreenService, ToasterService } from '@core/services';
import { BaseComponent, ROUTER_UTILS, setItem } from '@core/utils';
import { DashboardService } from '@pages/dashboard/dashboard.service';
import { setTheme } from 'ngx-bootstrap/utils';
import { takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';
import { StorageItem } from './@core/utils/local-storage.utils';
import { Constants } from './@shared/constants/app.constants';
import { UserDetails } from './@shared/models/user.modal';
import { UserDetailsService } from './@shared/services';
import { UserDataService } from './@shared/services/user-data.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent extends BaseComponent implements OnInit {
  userRoleDetails!: string;
  isLoggedIn = false;
  isUserDetailsFetch = false;
  positionCode!: string;
  browserRefresh = false;
  url!: string;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private readonly msalGuardConfig: MsalGuardConfiguration,
    private readonly seoService: SeoService,
    private readonly splashScreenService: SplashScreenService,
    private readonly router: Router,
    private readonly msalAuthService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService,
    private readonly userDetailsService: UserDetailsService,
    private readonly userDataService: UserDataService,
    private readonly toasterService: ToasterService,
    private readonly dashboardService: DashboardService,
  ) {
    super();
    setTheme('bs5');
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.browserRefresh = !router.navigated;
      }
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit(): void {
    this.isUserLoggedIn();
    this.checkIsLoginInProcess();
    this.runGlobalServices();
    this.initializeRouterEventListener();
  }

  checkIsLoginInProcess(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.isUserLoggedIn();
      });
  }

  getUserDetails(): void {
    if (!this.isLoggedIn) {
      return;
    }
    this.userDetailsService.getUserDetails().subscribe((user: UserDetails) => {
      if (user) {
        this.userDetailsService.setUser(user);
        setItem(StorageItem.userEmail, user.userPrincipalName);
        this.getUserProfile(user.userPrincipalName);
        this.isUserDetailsFetch = true;
      }
    });
  }

  initializeRouterEventListener(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.splashScreenService.hide();
        window.scrollTo(0, 0);
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, Constants.splashScreenTimeout);
      }
    });
  }

  private runGlobalServices(): void {
    this.seoService.init();
  }

  logout(): void {
    this.msalAuthService.logout();
  }

  isUserLoggedIn(): void {
    this.isLoggedIn = this.msalAuthService.instance.getAllAccounts().length > 0;
    this.getUserDetails();
    this.fetchUserRole();
  }

  fetchUserRole() {
    this.userDataService.userRoleDetails$.pipe(takeUntil(this.destroy$)).subscribe((userRole: string) => {
      this.userRoleDetails = userRole;
    });
  }

  getUserProfile(userEmail: string): void {
    this.dashboardService
      .getUserProfile(userEmail)
      .pipe()
      .subscribe((UserProfile) => {
        setItem(StorageItem.userProfile, UserProfile);
        this.positionCode = UserProfile.data.positionCode;
        this.userDataService.updateRoleDetailsData(UserProfile.data.positionCode);
        this.userDataService.updateIsUserAdminData(UserProfile.data.isScheduleEditor);
        this.userDataService.updateCompanyId(UserProfile.data.worksFor);
        this.proceedToMoveToRespectiveDashboard();
      });
  }

  proceedToMoveToRespectiveDashboard(): void {
    if (!this.browserRefresh && this.url === '/') {
      switch (this.positionCode) {
        case this.constants.roles.foreman:
          this.router.navigate([ROUTER_UTILS.config.navigation.foreman, ROUTER_UTILS.config.base.dashboard]);
          break;
        case this.constants.roles.ShopSupervisor:
        // this.router.navigate([ROUTER_UTILS.config.shopManagerNavigation.root, ROUTER_UTILS.config.shopManagerNavigation.dashboard]);
        // break;
        case this.constants.roles.labour:
        // this.router.navigate([this.path.crewNavigation.crewMember]);
        // break;
        case this.constants.roles.mechanic:
        // this.router.navigate([ROUTER_UTILS.config.mechanicNavigation.root]);
        // break;
        default:
          this.router.navigate([ROUTER_UTILS.config.navigation.foreman, ROUTER_UTILS.config.errorResponse.unauthorized]);
          // this.router.navigate([this.path.errorResponse.notFound]);
          break;
      }
    }
    this.browserRefresh = false;
  }

  alertUser(): void {
    this.toasterService.info('Coming soon');
  }
}
