import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ROUTER_UTILS } from '@core/utils';
import { UserDataService } from '@sharedServices/user-data.service';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/@shared/constants/app.constants';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  constructor(private readonly userDataService: UserDataService, private readonly route: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // we read path form url then we check as per position code match url or not if yes then we allow only user to see that path
    const constant = Constants;
    const path = route.routeConfig?.path;
    if (path) {
      return new Promise((resolve, reject) => {
        this.userDataService.userRoleDetails$.subscribe({
          next: (positionCode) => {
            if (positionCode) {
              switch (positionCode) {
                case constant.roles.foreman:
                  if (path.includes(constant.users.foreman)) {
                    return resolve(true);
                  }
                  break;
                case constant.roles.labour:
                  if (path.includes(constant.users.labour)) {
                    return resolve(false);
                  }
                  break;
                case constant.roles.mechanic:
                  if (path.includes(constant.users.mechanic)) {
                    return resolve(false);
                  }
                  break;
                case constant.roles.ShopSupervisor:
                case constant.roles.shopAssist:
                  if (path.includes(constant.users.shopManager)) {
                    return resolve(false);
                  }
                  break;
                default:
                  this.route.navigate([ROUTER_UTILS.config.navigation.foreman, ROUTER_UTILS.config.errorResponse.unauthorized]);
                  return resolve(false);
              }
            } else {
              return false;
            }
          },
        });
      });
    } else {
      return false;
    }
  }
}
