import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { AuthorizedLayoutModule } from '@shell/ui/authorized-layout/authorized-layout.module';
import { HeaderModule } from '@shell/ui/header/header.module';
import { DirectivesModule } from 'src/app/@shared/directives/directives.module';
import { FontAwesomeIconsModule } from 'src/app/@shared/modules/fontawesome.module';
import { NavigationBarRoutingModule } from './navigation-bar-routing.module';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';

@NgModule({
  declarations: [NavigationBarComponent],
  imports: [CommonModule, HeaderModule, NavigationBarRoutingModule, AuthorizedLayoutModule, CoreModule, DirectivesModule, FontAwesomeIconsModule],
  exports: [NavigationBarComponent],
})
export class NavigationBarModule {}
