import { GlobalConfig } from 'ngx-toastr';

export const toasterConfig: Partial<GlobalConfig> = {
  timeOut: 3000,
  positionClass: 'toast-top-right',
  preventDuplicates: true,
  closeButton: true,
  countDuplicates: true,
  includeTitleDuplicates: true,
  enableHtml: true,
  tapToDismiss: true,
  progressBar: true,
};
