import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL_UTIL, BaseComponent } from '@core/utils';
import { Observable } from 'rxjs';
import { CompaniesListResponse, EmployeeListCompanwiseResponse, HttpStatus } from './../../../@shared/models/add-employee.modal';

@Injectable({
  providedIn: 'root',
})
export class AddEmployeeService extends BaseComponent {
  constructor(private readonly httpClient: HttpClient) {
    super();
  }

  getAllCompanies(): Observable<CompaniesListResponse> {
    return this.httpClient.get<CompaniesListResponse>(`${API_URL_UTIL.foreman.getAllCompanies}`);
  }

  getEmplyeeList(companyId: number, searchText: string, page: number): Observable<EmployeeListCompanwiseResponse> {
    const params = new HttpParams().set('companyId', companyId).set('pageSize', this.constants.addEmployee.addEmployeePageNo).set('page', page).set('searchText', searchText);
    return this.httpClient.get<EmployeeListCompanwiseResponse>(`${API_URL_UTIL.foreman.getAllEmployeesByCompanyId}`, { params });
  }

  updateCrewDetails(JobScheduleId: string, email: string, updateTable: Array<any>, startDate: string): Observable<HttpStatus> {
    const params = new HttpParams().set('JobScheduleId', JobScheduleId).set('LoggedInUser', `${email}`).set('Startdate', startDate);
    return this.httpClient.post<HttpStatus>(API_URL_UTIL.foreman.saveCrewByJobScheduleId, { crewMembersDetails: updateTable }, { params });
  }
}
