<div class="row">
  <div class="col-lg-6">
    <ng-template [ngTemplateOutlet]="unselectedTemplate"></ng-template>
  </div>
  <div class="col-lg-6 side-table fix-height">
    <ng-template [ngTemplateOutlet]="selectedTemplate"></ng-template>
    <div class="float-end">
      <button class="btn btn-primary" (click)="updateCostCode()">Update</button>
      <button class="btn btn-outline-danger ms-2" (click)="closeModal()">Cancel</button>
    </div>
  </div>
</div>

<ng-template #unselectedTemplate>
  <div class="form-group mt-1">
    <label for="phase_search_1" class="col-form-label custom-label pb-3">Select cost-code to add to the timecard</label>
    <div class="position-relative">
      <img class="search-icon" [src]="constants.staticImages.searchIcon" alt="search icon" />
      <input
        type="text"
        class="form-control ps-5 pt-1"
        name="phase_search_1"
        aria-describedby="phase_search_1"
        placeholder="Search"
        [(ngModel)]="searchString1"
        (change)="filterLeftCostCode()"
      />
    </div>
    <div class="pt-4">
      <div class="scrollbar-y-auto card-height small-screen-card-height">
        <div class="border-bottom pb-3 mt-4" *ngFor="let costCode of costCodeListLeft; let i = index">
          <div class="d-flex justify-content-between">
            <div class="table-cell">{{ costCode?.costCodeDescription }}</div>
            <div class="float-end pe-2">
              <div class="d-flex flex-row">
                <button class="border-0 ms-2" (click)="addCostCodeToCurrentList(costCode)" title="add">
                  <img [src]="constants.staticImages.plusIconForm" alt="plus icon" />
                </button>
              </div>
            </div>
          </div>
          <div>
            <span class="custom-text">{{ costCode?.costCode }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #selectedTemplate>
  <div class="form-group">
    <label for="phase_search_2 " class="col-form-label custom-label pb-3">cost-code on current timecard</label>
    <div class="position-relative">
      <img class="search-icon" [src]="constants.staticImages.searchIcon" alt="search icon" />
      <input
        type="text "
        class="form-control text-start ps-5"
        name="phase_search_2 "
        aria-describedby="phase_search_2 "
        placeholder="Search "
        [(ngModel)]="searchString2"
        (input)="filterRightCostCode()"
      />
    </div>
    <div class="pt-4">
      <ng-container *ngFor="let existingData of costCodeListRight">
        <div class="border-bottom py-3">
          <div class="d-flex justify-content-between">
            <div class="table-cell">{{ existingData?.costCodeDescription }}</div>
            <div class="float-end">
              <div class="d-flex flex-row">
                <div class="form-check form-switch"></div>
                <button class="border-0" title="remove " (click)="showRemoveCostCodeFromCurrentListDialog(removeCostCodeTemplate, existingData)">
                  <img [src]="constants.staticImages.crossIcon" alt="plus icon" />
                </button>
              </div>
            </div>
          </div>
          <div>
            <span class="custom-text">{{ existingData?.costCode }}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #removeCostCodeTemplate>
  <app-modal title="Remove CostCode" [modalRef]="modalRef" [positiveResponse]="'Yes'" (onAction)="removeCostCodeFromCurrentList($event)">
    <p class="modal-body">Are you sure you want to remove?</p>
  </app-modal>
</ng-template>
