import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCrudService } from '@core/services';
import { API_URL_UTIL } from '@core/utils';
import { Observable } from 'rxjs';
import { EquipmentTimeCardUpdateParams, TimecardApiResponse, TimeCardUpdateParams } from '../../@shared/models/timecard-modal';

@Injectable({
  providedIn: 'root',
})
export class TimeCardService extends BaseCrudService {
  getBaseAPIPath(): string {
    return API_URL_UTIL.foreman.root;
  }

  getCrewPhaseDetailsByJobScheduleId(JobScheduleId: string, startDate: string): Observable<TimecardApiResponse> {
    const params = new HttpParams().set('JobScheduleId', JobScheduleId).set('startDate', `${startDate}`);
    return this.httpClient.get<TimecardApiResponse>(`Foreman/${API_URL_UTIL.foreman.getCrewPhaseDetailsByJobScheduleId}`, { params });
  }

  updateCrewPhaseDetailsbyJobScheduleId(JobScheduleId: string, email: string, updateTable: Array<TimeCardUpdateParams>): Observable<any> {
    const params = new HttpParams().set('JobScheduleId', JobScheduleId).set('LoggedInUser', `${email}`);
    return this.httpClient.post<any>(API_URL_UTIL.foreman.saveJobScheduleLoggedHours, updateTable, { params });
  }

  getEquipmentCrewMembers(equipmentId: string, equipmentCompanyId: string, startDate: string): Observable<any> {
    const params = new HttpParams().set('equipmentId', equipmentId).set('equipmentCompanyId', equipmentCompanyId).set('startDate', startDate);
    return this.httpClient.get<any>(`ShopManager/${API_URL_UTIL.shopManager.EquipmentCrewMembers}`, { params });
  }

  updateEquipmentTimeCard(updatedEquipment: EquipmentTimeCardUpdateParams): Observable<any> {
    return this.httpClient.post<any>(`ShopManager/${API_URL_UTIL.shopManager.EquipmentLoggedHours}`, updatedEquipment);
  }

  deleteTimePhaseApi(endPoint: string): Observable<any> {
    return this.httpClient.delete(endPoint);
  }
}
