import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RxJsUtils } from '@core/utils/rxjs.utils';
import { environment } from '@env/environment';
import { AuthService } from '@pages/auth/services/auth.service';
import { Observable } from 'rxjs';
import { AppLoaderService } from './../../@shared/services/app-loader.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthService, private readonly appLoaderService: AppLoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone({
      url: ['https://graph.microsoft.com/v1.0/me'].includes(request.url) ? `${request.url}` : `${environment.apiUrl}/${request.url}`,
    });
    this.appLoaderService.toggleLoader(true);

    const { urlWithParams } = request;

    return next.handle(request).pipe(RxJsUtils.shareDuplicate(urlWithParams));
  }
}
