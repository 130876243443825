<header class="header-setting">
  <div class="d-flex justify-content-between w-100 header-setting">
    <div class="d-flex justify-content-between align-items-center">
      <a [routerLink]="headerLink" [queryParamsHandling]="'merge'">
        <img [src]="constants.applicationLogoUrl" class="card-img-top img-fluid image-height pl-2" alt="Company Icon" />
      </a>
    </div>
    <div class="mt-2 head-text align-self-center d-none d-sm-block">
      {{ headerTitle | titlecase }}
    </div>
    <div class="align-self-center mt-1 d-flex align-items-center">
      <button class="btn btn-primary me-3 text-white bg-black" (click)="redirectAdmin()">Job Scheduler</button>
      <button class="float-end btn btn-primary me-3 text-white bg-black" (click)="openModalWithComponent(logout)" [title]="userName">Sign out</button>
      <em class="pi pi-cog me-3 text-white pointer fs-4" (click)="redirectProfilePage()"></em>
    </div>
  </div>
</header>

<ng-template #logout>
  <app-modal title="Sign out" [positiveResponse]="modalButtonName" (onAction)="logOut($event)">
    <p class="modal-body">Are you sure you want to sign out?</p>
  </app-modal>
</ng-template>
