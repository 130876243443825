import { ComponentFactoryResolver, ComponentRef, Directive, ElementRef, HostListener, Inject, OnDestroy, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { CommonService } from '@core/services';
import { faIcons } from '@core/utils';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { Subject, takeUntil } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';

/**
 * @whatitdoes it injects the spinner on buttons based on API call status.
 * @howtouse <button appShowLoaderOnApiCall></div>
 */
@Directive({
  selector: '[appShowLoaderOnApiCall]',
})
export class ShowLoaderOnApiCallDirective implements OnInit, OnDestroy {
  visible = false;
  loadingSubscription: Subscription = new Subscription();
  destroy$ = new Subject();
  faIconElement!: ComponentRef<FaIconComponent>;

  constructor(
    private readonly elem: ElementRef,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly renderer: Renderer2,
    private readonly commonService: CommonService,
    @Inject(ComponentFactoryResolver) private readonly factoryResolver: ComponentFactoryResolver,
  ) {}

  ngOnInit() {
    this.commonService.isApiCallInProgress$.pipe(takeUntil(this.destroy$)).subscribe((isApiCallInProgress) => {
      if (!isApiCallInProgress && this.elem.nativeElement && this.faIconElement?.location?.nativeElement) {
        this.renderer.removeClass(this.elem.nativeElement, 'disabled');
        this.renderer.removeAttribute(this.elem.nativeElement, 'disabled');
        this.renderer.removeChild(this.elem.nativeElement, this.faIconElement?.location?.nativeElement);
        this.faIconElement.destroy();
      }
    });
  }

  initializeSpinnerComponentElement() {
    const factory = this.factoryResolver.resolveComponentFactory(FaIconComponent);
    const faIconElement = this.viewContainerRef.createComponent(factory);
    faIconElement.instance.icon = faIcons.faSpinner;
    faIconElement.instance.spin = true;
    this.renderer.addClass(faIconElement.location.nativeElement, 'btn-loader-icon');
    faIconElement.instance.render();
    this.faIconElement = faIconElement;
  }

  ngOnDestroy() {
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }

  @HostListener('click', ['$event'])
  onClick(): void {
    this.initializeSpinnerComponentElement();
    this.renderer.addClass(this.elem.nativeElement, 'disabled');
    setTimeout(() => {
      this.renderer.setAttribute(this.elem.nativeElement, 'disabled', 'true');
    }, 0);
    this.renderer.appendChild(this.elem.nativeElement, this.faIconElement?.location?.nativeElement);
  }
}
