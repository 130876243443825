import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToasterService } from '../../../@core/services/toaster.service';
import { Constants } from '../../constants/app.constants';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss'],
})
export class SendEmailComponent {
  separator = ',';
  allowDuplicate = false;
  modalRef!: BsModalRef | undefined;
  email: string[] = [];
  emailPattern = new RegExp(Constants.validation.email);
  @Output() listEmail: EventEmitter<string[]> = new EventEmitter<string[]>();
  constructor(private readonly toasterService: ToasterService) {}

  async updateEmail(): Promise<void> {
    this.email = [
      ...this.email.filter((emailID) => {
        if (this.isValidEmailID(emailID)) {
          return true;
        } else {
          this.toasterService.warning('Invalid email ID');
          return false;
        }
      }),
    ];
    this.listEmail?.next(this.email);
  }

  isValidEmailID(emailID: string): boolean {
    return this.emailPattern.test(String(emailID).toLowerCase());
  }
}
