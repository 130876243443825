// Angular
import { animate, AnimationBuilder, style } from '@angular/animations';
import { ElementRef, Injectable } from '@angular/core';
import { Constants } from '@constants/*';

@Injectable({ providedIn: 'root' })
export class SplashScreenService {
  // Private properties
  private el!: ElementRef;
  private stopped!: boolean;
  private readonly animateValue = 500;

  /**
   * Service constructor
   *
   * @param animationBuilder: AnimationBuilder
   */
  constructor(private readonly animationBuilder: AnimationBuilder) {}

  /**
   * Init
   *
   * @param element: ElementRef
   */
  init(element: ElementRef) {
    this.el = element;
  }

  /**
   * Hide
   */
  hide() {
    if (this.stopped) {
      return;
    }

    const player = this.animationBuilder.build([style({ opacity: '1' }), animate(this.animateValue, style({ opacity: '0' }))]).create(this.el.nativeElement);

    player.onDone(() => {
      if (typeof this.el.nativeElement.remove === 'function') {
        this.el.nativeElement.remove();
      } else {
        this.el.nativeElement.style.display = 'none';
      }
      this.stopped = true;
    });

    setTimeout(() => player.play(), Constants.splashScreenTimeout);
  }
}
