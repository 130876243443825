export const MESSAGES = {
  changePasswordSuccess: 'Password changed successfully',
  profileUpdateSuccess: 'Profile updated successfully',
  error: {
    userNotFound: 'Username is not found',
    unknownError: 'Something gone wrong',
  },
  formError: {
    timecardFormError: 'Please correct error first',
  },
};
