import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { HttpEventType } from '@angular/common/http';
import { ToasterService } from '@core/services';
import { UploadedFileData } from '@pages/observations/models';
import { UploadFilesService } from '@sharedServices/upload-files.service';
import heic2any from 'heic2any';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';
import { API_URL_UTIL } from '../../../@core/utils/api-url.utils';
import { BaseComponent } from '../../../@core/utils/base.component';
import { thumbnail } from '../../constants/defaultThumbnail.constants';
import { ViewerType } from '../../modules/media-viewer/models/viewer.model';

@Component({
  selector: 'app-upload-files',
  styleUrls: ['./upload-files.component.scss'],
  templateUrl: './upload-files.component.html',
})
export class UploadFilesComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() readOnlyApp!: boolean;
  uploadFileForm!: UntypedFormGroup;
  addedStatus!: string;
  files: any[] = [];
  totalFiles: any[] = [];
  isEnable!: boolean;
  isOnDrag!: boolean;
  modalRef!: BsModalRef;
  selectedFileIndex!: number;
  enableRealTimeProgressBar = false;
  imageLoadErrorCount = 0;
  fileUploadAttempt = 0;
  mediaType = ViewerType.IMAGE;
  @ViewChild('fileDropRef', { static: true }) fileDropRef!: ElementRef;

  @Input() selectedJobScheduleId!: string;
  @Input() isRequired!: boolean;
  @Input() progress = 0;
  @Input() maxfiles = 100;
  @Input() allowedExtensions: string[] = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/heif',
    'image/heic',
    'video/mp4',
    'video/mov',
    'video/quicktime',
    'video/x-msvideo',
    'video/webm',
    'video/x-matroska',
  ];
  @Input() fileExtensions: string[] = ['png', 'jpeg', 'jpg', 'heif', 'heic', 'mp4', 'mov', 'webm', 'mkv', 'm4v'];

  @Input() isParentSubmitted = false;
  @Input() onReset!: boolean;
  @Input() uploadedFilesListReadonly!: {
    filepath: string;
    originalFilename: string;
    physicalFilename: string;
    isVideo: boolean;
    thumbnail: string;
  }[];
  @Output() allUploaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() uploadedFiles: EventEmitter<UploadedFileData[]> = new EventEmitter<UploadedFileData[]>();
  imageLoadError = new Subject<string>();
  validateDuration = false;
  hasFilesLoaded = true;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly modalService: BsModalService,
    private readonly uploadFilesService: UploadFilesService,
    private readonly toasterService: ToasterService,
    private readonly cdf: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.reset();
    this.setupImageLoadErrorOperation();
  }

  setupImageLoadErrorOperation(): void {
    this.imageLoadError.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.imageLoadErrorCount++;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isParentSubmitted && this.uploadFileForm) {
      this.uploadFileForm.updateValueAndValidity();
      if (this.uploadFileForm.invalid) {
        this.uploadFileForm.markAllAsTouched();
        this.uploadFileForm.markAsDirty();
      }
    }

    if (this.onReset) {
      this.uploadFileForm?.reset();
      this.files = [];
    }
  }

  formatBytes(bytes: number, decimals: any): number | string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  reset(): void {
    this.isEnable = false;
    this.addedStatus = '';
    this.isOnDrag = false;
    this.initializeFormGroup();
  }

  initializeFormGroup(): void {
    this.uploadFileForm = this.formBuilder.group({
      file: new UntypedFormControl([]),
    });
    if (this.isRequired) {
      this.uploadFileForm.get('file')?.addValidators(Validators.required);
    }
  }

  onFileUploadCheck(event: any): void {
    const files = [...event.target.files];
    if (files.length > this.maxfiles || files.length + this._files.length > this.maxfiles) {
      this.toasterService.info(`You can upload max ${this.maxfiles} files`);
      return;
    }
    this.isOnDrag = false;
    this.addedStatus = 'added';
    const hasError = this.checkFiles(files, 10000000000); // 1000 mb file we can upload from here

    if (!hasError.hasError && this.selectedJobScheduleId) {
      this.prepareFilesList(files, event);
    } else {
      this.toasterService.info(hasError.errMsg);
    }
  }

  isPromise(p: any) {
    if (typeof p === 'object' && typeof p.then === 'function') {
      return true;
    }
    return false;
  }

  prepareFilesList(files: Array<any>, event: any): Promise<string> {
    let fileIndex = this.fileUploadAttempt === 0 || this.totalFiles.length === 0 ? 0 : this.totalFiles.length;
    return new Promise((resolve) => {
      Promise.all(
        files.map(async (file, index) => {
          const ext = file.type ? file.type.split('image/').pop() : file.name.split('.').pop().toLowerCase();
          let thumbPromise: Promise<any>;
          let thumbnailRes: any;
          return new Promise(async (resolve) => {
            if (ext === 'heic' || ext === 'heif') {
              thumbPromise = heic2any({
                blob: file,
                toType: 'image/jpeg',
              }).then(async (result: any) => {
                const newFile = new File([result], file.name.replace(this.constants.regaxOnlyToConvertFileExt, '.jpeg'), { type: 'image/jpeg' });
                file = newFile;
                return await this.generateThumbnail(newFile);
              });
            } else {
              //This is not a HEIC image so we can just resolve
              thumbnailRes = await this.generateThumbnail(file);
            }

            if (this.isPromise(thumbPromise)) {
              thumbnailRes = await thumbPromise;
            }

            if (thumbnailRes.videoDuration < 31) {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              file.progress = 0;
              reader.onload = () => {
                const newFileObj: any = {
                  file,
                  base64: reader.result,
                  data: {},
                  isDelete: false,
                  isUploaded: false,
                  isVideo: file.type.includes('video'),
                  thumbnail: thumbnailRes.thumbnail,
                };
                this.files.push(newFileObj);
                this.uploadSingleFile(newFileObj, fileIndex, files.length);

                fileIndex++;
              };
            }
            resolve(true);
          });
        }),
      ).then(() => {
        this.fileUploadAttempt++;
      });
    });
  }

  uploadSingleFile(item: UploadedFileData, index: number, totalFiles: number): void {
    const formData = new FormData();

    formData.append('JobScheduleID', this.selectedJobScheduleId);
    formData.append('Image', item.file);
    const uploadFileEndpoint = `${API_URL_UTIL.foreman.uploadFile}?index=${index}`;

    this.uploadFilesService
      .uploadFile(uploadFileEndpoint, formData, {
        observe: 'events',
        reportProgress: true,
      })
      .subscribe((result: any) => {
        if (result.type === HttpEventType.Sent) {
          this.uploadFilesSimulator(index);
        } else if (result.type === HttpEventType.UploadProgress) {
          this.files[index].file.progress = Math.round((100 / result.total) * result.loaded);
        } else if (result.type === HttpEventType.Response) {
          if (result.body?.data.status === 1) {
            this.files[index].file.progress = 100;
            this.files[index].isUploaded = true;
            this.files[index].data = result?.body?.data;
            this.uploadedFiles.emit([...this.files].filter((file) => file.file.progress === 100));
            this.totalFiles = [...this.files];
            this.cdf.detectChanges();
          }
          if (index + 1 === totalFiles) {
            this.allUploaded.emit(true);
            this.resetFileInput();
          }
        } else if (result.type === HttpEventType.ResponseHeader && !result.ok) {
          this.files.splice(index, 1);
          this.resetFileInput();
        }
      });
  }

  resetFileInput(): void {
    if (this.fileDropRef) {
      this.fileDropRef.nativeElement.value = '';
    }
  }

  checkFiles(
    files: any,
    size: number,
  ): {
    errMsg: string;
    hasError: boolean;
  } {
    let hasError = false;
    let errMsg = '';

    const fileTypes = this.allowedExtensions;
    for (const file of files) {
      if (!hasError) {
        if (file.size > size) {
          errMsg = 'File size is too large';
          hasError = true;
        } else if (file.type && fileTypes.indexOf(file.type) === -1) {
          errMsg = `Supported file formats: ${this.fileExtensions?.join(', ')}`;
          hasError = true;
        } else if (!file.type) {
          const fileExt = file.name.substring(file.name.lastIndexOf('.') + 1);
          if (this.fileExtensions.indexOf(fileExt) === -1) {
            errMsg = `${fileExt} format is not supported`;
            hasError = true;
          }
        }
      }
    }
    return {
      errMsg,
      hasError,
    };
  }

  uploadFilesSimulator(index: number): void {
    if (!this.enableRealTimeProgressBar) {
      setTimeout(() => {
        if (index >= this.files.length) {
          this.isEnable = true;
        } else {
          this.isEnable = false;
          if (this.files[index]?.file) {
            const progressInterval = setInterval(() => {
              if (this.files[index]?.file?.progress === 100) {
                clearInterval(progressInterval);
                this.uploadFilesSimulator(index + 1);
              } else if (this.files[index]?.file) {
                if (this.files[index].file.progress >= 95) {
                  this.files[index].file.progress = 0;
                } else {
                  this.files[index].file.progress += 5;
                }
              }
            }, 300);
          } else {
            this.files[index].file.progress === 0;
          }
        }
      }, 100);
    }
  }

  showRemoveFileModal(template: TemplateRef<unknown>, index: number): void {
    this.selectedFileIndex = index;
    this.modalRef = this.modalService.show(template);
  }

  onConfirmRemove(toRemove: boolean): void {
    if (toRemove) {
      this.deleteFile(this.selectedFileIndex);
    }
  }

  deleteFile(index: number): void {
    this.files[index].isDelete = true;
    this.selectedFileIndex = -1;
    this.resetFileInput();
  }

  onImageLoadError(index: any): void {
    if (this.uploadedFilesListReadonly[index]) {
      this.imageLoadError.next(this.uploadedFilesListReadonly[index].filepath);
    }
  }

  get _files() {
    return [...this.files].filter((file) => !file.isDelete);
  }

  generateThumbnail(file: File) {
    const fileUrl = URL.createObjectURL(file);
    return new Promise((resolve, reject) => {
      if (file.type.includes('image')) {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = (e: any) => {
          const img = new Image();
          img.src = fileUrl;

          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const thumbnailWidth = 150,
              thumbnailHeight = 150;

            const squareSize = Math.min(thumbnailWidth, thumbnailHeight);
            canvas.width = squareSize;
            canvas.height = squareSize;
            if (ctx) {
              ctx.drawImage(img, 0, 0, squareSize, squareSize);
              resolve({ thumbnail: canvas.toDataURL('image/jpeg', 0.7), videoDuration: 0 });
            }
          };
        };
      } else {
        const video = document.createElement('video');
        const canvas = document.createElement('canvas');
        const thumbnailWidth = 150,
          thumbnailHeight = 150;
        const squareSize = Math.min(thumbnailWidth, thumbnailHeight);
        canvas.width = squareSize;
        canvas.height = squareSize;

        video.oncanplay = function () {
          //need this for iphone
          video.currentTime = 2.5;
          video.oncanplay = null;
        };
        video.addEventListener('loadedmetadata', () => {});
        video.addEventListener('seeked', () => {
          const context = canvas.getContext('2d');

          if (video.duration > 31) {
            this.toasterService.info(this.constants.warningMessage.durationValidate.detail);
          }

          if (context) {
            context.drawImage(video, 0, 0, squareSize, squareSize);
            // Clean up the resources
            resolve({ thumbnail: canvas.toDataURL('image/jpeg'), videoDuration: video.duration });
          } else {
            reject(new Error(this.constants.canvasContextError));
          }
        });

        video.addEventListener('error', (error) => {
          reject(new Error(this.constants.videoLoadingError));
        });
        video.src = fileUrl;
      }
    });
  }

  onImageLoad(filepath: string) {
    if (this.imageLoadErrorCount > 0 && filepath) {
      window.open(filepath, '_blank');
    }
  }

  loadImageMedia(file: any): string {
    return file?.thumbnail ? file?.thumbnail : this.imageLoadErrorCount > 0 && file.filepath ? thumbnail.noPreviewImage : file.filepath;
  }
}
