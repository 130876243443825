import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { API_URL_UTIL, BaseComponent } from '@core/utils';
import { EquipmentEquipment } from '@pages/equipments/models/equipments.model';
import { AddEmployeeService } from '@pages/time-card/add-employee/add-employee.service';
import { UserDataService } from '@sharedServices/user-data.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs';
import { ApiResponse } from 'src/app/@shared/models';
import { SidebarModalService } from 'src/app/@shared/services/sidebar-modal.service';
import { ToasterService } from '../../../@core/services/toaster.service';
import { AllCompanies, CompaniesListResponse, HttpStatus } from '../../models/add-employee.modal';
import { EquipmentByCategory, EquipmentUpdateEquipment, TimeCardEquipment, TimeCardUpdateEquipment } from '../../models/add-equipment-modal';
import { AddEquipmentService } from './add-equipment.service';

@Component({
  selector: 'app-add-equipment',
  templateUrl: './add-equipment.component.html',
  styleUrls: ['./add-equipment.component.scss'],
})
export class AddEquipmentComponent extends BaseComponent implements OnInit {
  selectedDate!: Date;
  equipmentList!: Array<EquipmentByCategory>;
  confirmRemoveButtonTxt = 'Yes';
  leftSearchString!: string;
  rightSearchString!: string;
  modalRef!: BsModalRef | undefined;
  companyList!: Array<AllCompanies>;

  leftSideEquipmentList: Array<EquipmentByCategory> = [];
  _leftSideEquipmentList: Array<EquipmentByCategory> = [];
  @Input() rightSideEquipmentList: Array<TimeCardEquipment | EquipmentEquipment> = [];
  _rightSideEquipmentList: Array<TimeCardEquipment | EquipmentEquipment> = [];

  selectedEquipment!: TimeCardEquipment | EquipmentEquipment;

  @Input() origin!: 'TimeCard' | 'Equipment';
  @Input() jobScheduleId!: string;
  @Input() jobScheduleDate!: Date;
  @Input() enableTMSwitching!: boolean;
  @Input() selectedCompany!: number;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private readonly sidebarModalService: SidebarModalService,
    private readonly toasterService: ToasterService,
    private readonly addEquipmentService: AddEquipmentService,
    private readonly modalService: BsModalService,
    private readonly userDataService: UserDataService,
    private readonly addEmployeeService: AddEmployeeService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.rightSideEquipmentList = [...this.rightSideEquipmentList];
    this._rightSideEquipmentList = [...this.rightSideEquipmentList];
    this.getEquipmentList();
    this.getAllCompanies();
  }

  getEquipmentList(): void {
    if (this.jobScheduleId) {
      this.addEquipmentService
        .get<ApiResponse<EquipmentByCategory[]>>(null, this.getEquipmentListEndPoint())
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (apiResponse) => {
            this.leftSideEquipmentList = apiResponse.data;
            this._leftSideEquipmentList = apiResponse.data;
          },
        });
    }
  }

  getEquipmentListEndPoint(): string {
    return `${API_URL_UTIL.foreman.getEquipmentCategorywiseList}?CompanyId=${this.selectedCompany}`;
  }

  filterLeftSideList(): void {
    const searchString = this.leftSearchString?.toLowerCase().trim();
    this.leftSideEquipmentList = [...this._leftSideEquipmentList]
      .map((eqp) => {
        const jobEquipments = eqp.jobEquipments.filter(
          (jobEqp) => jobEqp?.description?.toLowerCase().includes(searchString) || jobEqp?.category?.toLowerCase().includes(searchString),
        );
        return { ...eqp, jobEquipments: jobEquipments };
      })
      .filter((eqp) => eqp.jobEquipments.length > 0);
  }

  filterRightSideList(): void {
    const searchString = this.rightSearchString?.toLowerCase().trim();
    this.rightSideEquipmentList = [...this._rightSideEquipmentList].filter((eqp) => {
      return eqp.category?.toLowerCase()?.includes(searchString) || eqp.description?.toLowerCase()?.includes(searchString);
    });
  }

  async addToRight(equipment: TimeCardEquipment | EquipmentEquipment): Promise<void> {
    let isAdded = false;
    this._rightSideEquipmentList.forEach((eqp) => {
      if (eqp.equipmentId === equipment.equipmentId && eqp.equipmentCompany === equipment.equipmentCompany) {
        isAdded = true;
      }
    });

    if (!isAdded) {
      if (this.origin === 'TimeCard') {
        equipment = equipment as TimeCardEquipment;
        equipment.equipmentCategory = equipment.category;
      } else {
        equipment = equipment as EquipmentEquipment;
        equipment.category = equipment.category;
      }
      this._rightSideEquipmentList.push(equipment);
      this.rightSideEquipmentList.push(equipment);
    }
  }

  onConfirmRemove(toRemove: boolean): void {
    if (toRemove) {
      let index = this._rightSideEquipmentList.findIndex((eqp) => eqp.equipmentId === this.selectedEquipment.equipmentId);
      this._rightSideEquipmentList.splice(index, 1);
      index = this.rightSideEquipmentList.findIndex((eqp) => eqp.equipmentId === this.selectedEquipment.equipmentId);
      this.rightSideEquipmentList.splice(index, 1);
    }
  }

  showRemoveEquipmentModal(template: TemplateRef<unknown>, data: TimeCardEquipment | EquipmentEquipment): void {
    this.selectedEquipment = data;
    this.modalRef = this.modalService.show(template);
  }

  updateEquipment(): void {
    if (this.origin === 'TimeCard') {
      const body: TimeCardUpdateEquipment = {
        equipmentlistDetails: [...this._rightSideEquipmentList] as unknown as TimeCardEquipment[],
      };
      this.addEquipmentService
        .add<HttpStatus, TimeCardUpdateEquipment>(body, this.updateEquipmentApiUrl())
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (timeCardApiResponse: HttpStatus) => {
            this.toasterService.success(timeCardApiResponse.message);
            this.sidebarModalService.hideModal();
            this.onClose.emit(true);
          },
          error: (timeCardApiResponse) => {
            this.toasterService.error(timeCardApiResponse.message);
          },
        });
    } else {
      const nonDefaultEquipmentList = ([...this._rightSideEquipmentList] as unknown as EquipmentEquipment[]).filter((eqp) => !eqp.isDefaultEquipment);
      const body: EquipmentUpdateEquipment = {
        jobScheduleId: this.jobScheduleId,
        jobEquipment: nonDefaultEquipmentList.map((eqp) => {
          return {
            equipmentId: eqp.equipmentId,
            equipmentCompany: eqp.equipmentCompany,
            category: eqp.category,
          };
        }) as unknown as EquipmentEquipment[],
      };
      this.addEquipmentService
        .add<HttpStatus, EquipmentUpdateEquipment>(body, this.updateEquipmentApiUrl())
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (equipmentApiResponse: HttpStatus) => {
            this.toasterService.success(equipmentApiResponse.message);
            this.sidebarModalService.hideModal();
            this.onClose.emit(true);
          },
          error: (equipmentApiResponse) => {
            this.toasterService.error(equipmentApiResponse.message);
          },
        });
    }
  }

  updateEquipmentApiUrl(): string {
    if (this.origin === 'TimeCard') {
      return `${API_URL_UTIL.foreman.saveEquipmentsByJobScheduleId}?JobScheduleId=${this.jobScheduleId}&LoggedInUser=${this.userDataService.getLoginUser()}`;
    } else {
      return `${API_URL_UTIL.foreman.updateEquipmentsByJobScheduleId}`;
    }
  }

  closeModal(): void {
    this.onClose.emit(true);
    this.sidebarModalService.hideModal();
  }

  isDefaultEquipment(equipment: TimeCardEquipment | EquipmentEquipment) {
    if (this.origin === 'Equipment') {
      equipment = equipment as unknown as EquipmentEquipment;
      return equipment?.isDefaultEquipment;
    } else {
      return false;
    }
  }

  getAllCompanies(): void {
    this.addEmployeeService
      .getAllCompanies()
      .pipe(takeUntil(this.destroy$))
      .subscribe((allCompanies: CompaniesListResponse) => {
        this.companyList = allCompanies.data;
      });
  }
}
