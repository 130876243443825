<div class="row">
  <div class="col-lg-6">
    <div class="form">
      <div class="form-group">
        <label for="company" class="col-form-label custom-label pb-3">Select Companies</label>
        <select class="form-select mb-3" aria-label=".form-select-lg example" [(ngModel)]="selectedCompany" (change)="getEmployeeList()">
          <option class="form-option" value="0" selected>All Companies</option>
          <option [value]="item.companyId" class="form-option" *ngFor="let item of companyList">{{ item.name }}</option>
        </select>
      </div>
      <div class="form-group mt-1">
        <label for="crew member" class="col-form-label custom-label pb-3">Select Crew member to add to the timecard</label>
        <div class="position-relative">
          <img class="search-icon" [src]="constants.staticImages.searchIcon" alt="search icon" />
          <input
            type="text"
            class="form-control ps-5 pt-1"
            name="Crew member"
            aria-describedby="helpId"
            placeholder="Search"
            [(ngModel)]="searchString"
            (change)="getEmployeeList()"
          />
        </div>
        <div class="pt-4 pe-2">
          <ng-container *ngFor="let crew of crewList; let index = index">
            <div class="border-bottom py-2 mt-4 mx-2">
              <div class="d-flex justify-content-between">
                <div class="table-cell">
                  <span>{{ crew?.fullName }}</span>
                </div>
                <div class="float-end plus-icon-container">
                  <button class="border-0" (click)="addEmployeeInPhase(crew)" title="add">
                    <img [src]="constants.staticImages.plusIconForm" alt="plus icon" />
                  </button>
                </div>
              </div>
              <div class="mt-1">
                <span [ngStyle]="{ color: crew.colorCode }" class="custom-jobtitle job-title-wight d-block" [title]="crew?.positionCode"
                  >{{ crew?.jobTitle }}- {{ crew?.positionCode }}</span
                >
                <span class="custom-text d-block">{{ crew.companyName }}</span>
              </div>
            </div>
          </ng-container>

          <div class="row mt-5">
            <div class="col-lg-12">
              <div class="d-flex flex-row flex-nowrap justify-content-evenly">
                <pagination
                  [totalItems]="totalEmployee"
                  [(ngModel)]="currentEmployeePageNo"
                  [maxSize]="maxSizePage"
                  [itemsPerPage]="constants.addEmployee.addEmployeePageNo"
                  (pageChanged)="pageChanged($event.page)"
                >
                </pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6 side-table">
    <div class="form-group">
      <label for="crew member" class="col-form-label custom-label pb-3">Crew on current timecard</label>
      <div class="position-relative">
        <img class="search-icon" [src]="constants.staticImages.searchIcon" alt="search icon" />
        <input
          type="text"
          class="form-control text-start ps-5"
          name="Crew member"
          aria-describedby="helpId"
          placeholder="Search"
          [(ngModel)]="filteredString"
          (input)="filterData()"
        />
      </div>
      <div class="pt-4 pe-2">
        <ng-container *ngFor="let crew of userAddedEmployee">
          <div class="border-bottom py-2 mt-4 mx-2">
            <div class="d-flex justify-content-between">
              <div class="table-cell">
                <span>{{ crew?.fullName }}</span>
              </div>
              <div class="float-end plus-icon-container" *ngIf="crew?.email !== logInUser">
                <button class="border-0" title="remove" (click)="openModalWithComponent(removeEmployee, crew)">
                  <img [src]="constants.staticImages.crossIcon" alt="plus icon" />
                </button>
              </div>
            </div>
            <div class="mt-1">
              <span [ngStyle]="{ color: crew?.colorCode }" class="custom-jobtitle job-title-wight d-block">{{ crew?.jobTitle }}-{{ crew?.positionCode }}</span>
              <span class="custom-text d-block">{{ crew?.companyName }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 mb-5">
        <div class="float-end">
          <button class="btn btn-primary" (click)="updateEmployee()">Update</button>
          <button class="btn btn-outline-primary ms-2" (click)="closeModal()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #removeEmployee>
  <app-modal title="Remove Employee" [modalRef]="modalRef" [positiveResponse]="modalButtonName" (onAction)="modalResponse($event)">
    <p class="modal-body">Are you sure you want to remove?</p>
  </app-modal>
</ng-template>
