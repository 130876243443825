import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ToasterService } from '@core/services';
import { BaseComponent, ROUTER_UTILS } from '@core/utils';
import { AuthService } from '@pages/auth/services/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs';
import { UserDataService } from '../../../@shared/services/user-data.service';

enum DropdownRouteName {
  NO_PARENT_ROUTE = 1,
  ADMIN = 2,
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent extends BaseComponent implements OnInit {
  modalButtonName = 'Yes';
  headerTitle = '';
  positionCode!: string;
  bsModalRef?: BsModalRef;
  dropdownOpts = DropdownRouteName;
  currentActiveParentRoute!: DropdownRouteName;
  headerLink: string[] = [];
  userName = '';
  companyId!: number;
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly cdf: ChangeDetectorRef,
    public readonly toasterService: ToasterService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly msalAuthService: MsalService,
    private readonly userDataService: UserDataService,
    private readonly modalService: BsModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.userName = this.userDataService.getLoginUser();
    this.setHeaderTitle();
    this.getCurrentPosition();
    this.fetchCompanyId();
  }

  onClickLogOut(): void {
    this.authService.logOut();
    const { root, login } = ROUTER_UTILS.config.auth;
    this.router.navigate(['/', root, login]);
  }

  setHeaderTitle(): void {
    this.userDataService.headerTitle$.subscribe((headerTitle: string) => {
      this.headerTitle = headerTitle;
      this.cdf.detectChanges();
    });
  }

  logOut($event: boolean): void {
    if ($event) {
      localStorage.clear();
      this.msalAuthService.logout();
    }
  }

  openModalWithComponent(logout: TemplateRef<unknown>) {
    this.modalService.show(logout);
  }

  getCurrentPosition(): void {
    this.userDataService.userRoleDetails$.subscribe((userRole) => {
      this.positionCode = userRole;
      switch (this.positionCode) {
        case this.constants.roles.foreman:
          this.headerLink = [this.path.navigation.foreman, this.path.base.dashboard];
          break;
        case this.constants.roles.ShopSupervisor:
          // this.headerLink = [this.path.shopManagerNavigation.root, this.path.shopManagerNavigation.dashboard];
          break;
        case this.constants.roles.labour:
          // this.headerLink = [this.path.crewNavigation.crewMember, this.path.base.dashboard];
          break;
        case this.constants.roles.shopAssist:
        case this.constants.roles.mechanic:
        default:
          break;
      }
      this.cdf.detectChanges();
    });
  }

  redirectAdmin(): void {
    this.router.navigate([`${ROUTER_UTILS.config.navigation.foreman}/${ROUTER_UTILS.config.admin.root}`], {
      queryParamsHandling: 'merge',
      queryParams: {
        companyId: this.companyId,
        date: null,
      },
    });
  }

  redirectProfilePage(): void {
    this.router.navigate([`${ROUTER_UTILS.config.user.profileDetails}`]);
  }

  fetchCompanyId() {
    this.userDataService.companyId$.pipe(takeUntil(this.destroy$)).subscribe((companyId: number) => {
      this.companyId = companyId;
    });
  }
}
