<div class="row border-top-2 navigation-height">
  <div class="d-flex flex-lg-row flex-wrap justify-content-evenly navigation-fix">
    <!-- <div class="nav-item-wrapper mx-sm-1" *ngFor="let item of navigation">
      <a class="py-2" [routerLink]="item.route" [queryParamsHandling]="'merge'" routerLinkActive="active" [appImageIconSrc]="item.icon" [hoverImgSrc]="item.hoverIcon">
        <fa-icon [icon]="faIcons.faCheckCircle" class="green-icon" *ngIf="status[item._isCompletedKey]"></fa-icon>
        <span class="navigation-text ms-2">{{ item.title }}</span>
      </a>
    </div> -->
    <div class="nav-item-wrapper mx-sm-1" *ngFor="let item of navigation">
      <a class="py-2" [routerLink]="item.route" [queryParamsHandling]="'merge'" routerLinkActive="active" [appImageIconSrc]="item.icon" [hoverImgSrc]="item.hoverIcon">
        <fa-icon [icon]="faIcons.faCheckCircle" class="green-icon" *ngIf="status[item._isCompletedKey]"></fa-icon>
        <span class="navigation-text ms-2">{{ item.title }}</span>
      </a>
    </div>
  </div>
</div>

<div class="card fix-height-timecard">
  <div class="card-body">
    <router-outlet></router-outlet>
  </div>
</div>
