<div class="row custom-detail">
  <div class="col-8 mb-2">
    <div class="ms-2 my-2 d-flex flex-nowrap flex justify-content-lg-between py-2">
      <div>
        <span class="custom-name">{{ crewDetail?.fullName }}</span>
        <span [ngStyle]="{ color: crewDetail?.colorCode }" class="jobtitle-color d-block font-wight-job">{{ crewDetail?.jobTitle }}-{{ crewDetail?.positionCode }}</span>
      </div>
    </div>
    <span class="ms-2 d-block" *ngIf="crewDetail?.workPhone">
      <img class="icon-hight" [src]="constants.staticImages.phoneLogo" alt="phone" />
      <span class="ms-2 font-14">{{ crewDetail?.workPhone }}</span>
    </span>
    <span class="ms-2 d-block" *ngIf="crewDetail?.email">
      <img class="icon-hight" [src]="constants.staticImages.emailImage" alt="phone" />
      <span class="ms-2 font-14">{{ crewDetail?.email }}</span>
    </span>
  </div>
  <div class="col-4 my-auto">
    <div class="d-flex flex-column align-items-end">
      <div>
        <div class="">
          <img class="d-inline-block img-calender mx-2" [src]="constants.staticImages.icons.disableCalender" alt="calender" />
          <span class="fontsize-16">{{ crewDetail?.weekStartDate | date : 'MM/dd/yy' }} - {{ crewDetail?.weekEndDate | date : 'MM/dd/yy' }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="mt-5">
  <section class="my-2 history">
    <strong class="d-block text-end me-3 f-20 my-3">Total Hours: {{ crewDetail?.totalHours }}</strong>
    <p-table
      styleClass="p-datatable-gridlines"
      class="delivery-info-table"
      [value]="crewDetail.employeeWeeklyLoggedHoursDetails"
      dataKey="id"
      responsiveLayout="scroll"
      *ngIf="crewDetail?.employeeWeeklyLoggedHoursDetails?.length; else noDataTemplate"
    >
      <ng-template pTemplate="header">
        <tr>
          <th class="table-column-header text-left fix-th-border first">
            <span class="table-heading">Date</span>
          </th>
          <th class="table-column-header text-left fix-th-border">
            <span class="table-heading">Job</span>
          </th>
          <th class="table-column-header fix-th-border">
            <span class="table-heading">Phase</span>
          </th>
          <th class="table-column-header fix-th-border">
            <span class="table-heading">Entered By</span>
          </th>
          <th class="table-column-header fix-th-border text-center">
            <span class="table-heading">Hours</span>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-emp>
        <ng-container>
          <tr class="text-center">
            <td class="table-column first">
              <span class="table-cell">{{ emp?.date | date : 'MM/dd/yy' }}</span>
            </td>
            <td class="table-column" [title]="emp?.jobName">
              <span class="table-cell text-truncate description job">{{ emp?.jobName }}</span>
            </td>
            <td class="table-column text-truncate phase" [title]="emp?.phaseName">
              <span class="table-cell">{{ emp?.phaseName }}</span>
            </td>
            <td class="table-column text-truncate" [title]="emp?.enterBy">
              <span>{{ emp?.enterBy }}</span>
            </td>
            <td class="table-column text-center text-truncate hours" [ngClass]="{ danger: emp?.isNoShow }">
              <strong>{{ emp?.loggedHours }}</strong>
            </td>
          </tr>
        </ng-container>
      </ng-template>
    </p-table>
  </section>
</section>

<ng-template #noDataTemplate>
  <div class="d-flex justify-content-center mt-5">
    <p>No received items yet</p>
  </div>
</ng-template>
