// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { versions } from './versions';

export const environment = {
  versions,
  production: false,
  apiUrl: 'https://dev-trimor-api.azurewebsites.net',
  persistUserSession: true, // if the user login session needs to be persisted across browser tabs
  sentryKey: 'https://0dc0376a1d294c62a75d2ae411714492@sentry.thesunflowerlab.com/51',
  enableOtpBasedLogin: false, // true means user will be able to login with otp else will have to login with email & password only
  microsoftKeys: {
    clientId: '30148f17-707a-496a-8792-6c1444e5c615',
    protectedResourceMap: {
      backEnd: 'https://dev-trimor-api.azurewebsites.net/*',
      microSoft: 'https://graph.microsoft.com/v1.0/me',
    },
    authority: 'https://login.microsoftonline.com/',
    DirectoryID: 'ee5d6ef2-372b-4831-b88d-b8ca7635926a',
  },
  enableMediaViewer: true,
  apryseConfiguration: {
    path: '/assets/lib/lib',
    licenseKey: 'demo:1690874283843:7c47811603000000000a1decdbf4a35f844c4137bf1e49f0360ca718b1',
    // sign up to get a key at https://dev.apryse.com
    // enableFilePicker: true,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
