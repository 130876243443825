import { Component, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent, ROUTER_UTILS, StorageItem, setItem } from '@core/utils';
import { DashboardService } from '@pages/dashboard/dashboard.service';
import { UserDetailsService } from '@sharedServices/user-details.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs';
import { UserDetails } from '../../models';

@Component({
  selector: 'app-common-cancel',
  templateUrl: './common-cancel.component.html',
  styleUrls: ['./common-cancel.component.scss'],
})
export class CommonCancelComponent extends BaseComponent {
  constructor(
    private readonly activeRoutes: ActivatedRoute,
    private readonly routes: Router,
    private readonly popupService: BsModalService,
    private readonly userDetailsService: UserDetailsService,
    private readonly dashboardService: DashboardService,
  ) {
    super();
  }

  companyId!: number;
  modalButtonName = 'Yes';
  modalRef!: BsModalRef | undefined;

  ngOnInit(): void {
    this.setCompanyId();
  }

  setCompanyId(): void {
    if (this.activeRoutes.snapshot.queryParams.companyId) {
      this.companyId = this.activeRoutes.snapshot.queryParams.companyId;
    } else {
      this.getUserDetails();
    }
  }

  getUserDetails(): void {
    this.userDetailsService.getUserDetails().subscribe((user: UserDetails) => {
      if (user) {
        this.userDetailsService.setUser(user);
        setItem(StorageItem.userEmail, user.userPrincipalName);
        this.getUserProfile(user.userPrincipalName);
      }
    });
  }

  getUserProfile(email: string): void {
    this.dashboardService
      .getUserProfile(email)
      .pipe(takeUntil(this.destroy$))
      .subscribe((UserProfile) => {
        setItem(StorageItem.userProfile, UserProfile);
        if (UserProfile?.data?.hrCompanyDetail?.companyId) {
          this.companyId = UserProfile?.data?.hrCompanyDetail?.companyId;
        }
      });
  }

  cancelJob(): void {
    this.routes.navigate([ROUTER_UTILS.config.navigation.foreman, ROUTER_UTILS.config.admin.root, this.path.navigation.scheduler], {
      queryParams: { companyId: this.companyId },
      queryParamsHandling: 'merge',
    });
  }

  cancleConfirmationPopup(popUpTemplate: TemplateRef<any>): void {
    this.popupService.show(popUpTemplate);
  }

  cancelModalResponse($event: boolean): void {
    if ($event) {
      this.cancelJob();
    }
  }
}
