import { Injectable } from '@angular/core';
import { BaseCrudService } from '@core/services';
import { API_URL_UTIL } from '@core/utils';

@Injectable({
  providedIn: 'root',
})
export class FullCalendarService extends BaseCrudService {
  getBaseAPIPath(): string {
    return API_URL_UTIL.foreman.admin.root;
  }
}
