<label [class.required]="isRequired">Files</label>
<div class="thumbnail-row">
  <div class="my-1">
    <form [formGroup]="uploadFileForm" *ngIf="!readOnlyApp">
      <div class="container" [class.hidden]="_files.length >= maxfiles">
        <input
          formControlName="file"
          type="file"
          #fileDropRef
          id="fileDropRef"
          accept="image/png,image/jpeg,image/jpg,image/heif,.heic,.heif,video/mp4,video/mov,video/hevc,video/avi,video/mkv,video/x-flv,video/x-ms-wmv,video/hevc"
          multiple
          (change)="onFileUploadCheck($event)"
        />
        <h3 class="center">
          <img class="file-upload-img ms-1" src="assets/images/upload-cloud (1)@2x.png" alt="" />
          <span>Upload</span>
        </h3>
      </div>
      <app-error-messages [control]="uploadFileForm.get('file')"></app-error-messages>
      <small class="text-danger" *ngIf="imageLoadErrorCount">
        It's look like you don't have access to view file in media viewer, please login in share point or contact the admin.. <br />
      </small>
    </form>
  </div>
  <ng-container *ngFor="let file of files; let i = index">
    <div class="thumbnail-container" *ngIf="!file.isDelete">
      <fa-icon *ngIf="file.file?.progress === 100" class="cross-icon" [icon]="faIcons.faCircleXmark" (click)="showRemoveFileModal(removeFileTemplate, i)" title="Remove"></fa-icon>
      <video
        *ngIf="file.isVideo && (file?.data | json) != '{}'; else imageView"
        class="thumbnail"
        [poster]="file.thumbnail"
        subtitles="test"
        descriptions="test"
        appMediaViewer
        [viewItems]="files"
        [allowThumbnail]="true"
        [enableZoom]="true"
        [isPlaying]="false"
        [enableRotate]="true"
        [imageIndex]="i"
        [fileKey]="'filepath'"
        [src]="file?.data?.filepath"
      >
        <source [src]="file?.data?.filepath" type="video/mp4" />
      </video>
      <ng-template #imageView>
        <img
          appMediaViewer
          [viewItems]="files"
          [allowThumbnail]="true"
          [enableZoom]="true"
          [isPlaying]="false"
          [enableRotate]="true"
          [imageIndex]="i"
          [fileKey]="'filepath'"
          class="thumbnail"
          [src]="file?.thumbnail"
          [alt]="file?.file?.name"
        />
      </ng-template>
      <img *ngIf="file?.isVideo" [src]="constants.staticImages.icons.videoIcon" alt="Video icon" class="preview-video-icon" />

      <span *ngIf="!file.file?.loaded"></span>
      <div class="progress-cont" *ngIf="file.file?.progress !== 100">
        <div class="progress" [style.width]="file.file?.progress + '%'"></div>
      </div>
    </div>
  </ng-container>
</div>
<!-- Just show images like gallery viewer -->
<div class="thumbnail-row image-viewer d-flex flex-wrap" *ngIf="uploadedFilesListReadonly">
  <!-- check image/video can be loaded or not -->
  <img
    class="hidden"
    *ngIf="!uploadedFilesListReadonly[0]?.isVideo"
    [alt]="uploadedFilesListReadonly[0]?.filepath"
    [src]="uploadedFilesListReadonly[0]?.filepath"
    (error)="onImageLoadError(0)"
  />
  <video class="hidden" *ngIf="uploadedFilesListReadonly[0]?.isVideo" [src]="uploadedFilesListReadonly[0]?.filepath" (error)="onImageLoadError(0)">
    <source [src]="uploadedFilesListReadonly[0]?.filepath" type="video/mp4" />
  </video>
  <ng-container *ngFor="let file of uploadedFilesListReadonly; let i = index">
    <div class="thumbnail-container" (click)="onImageLoad(file.filepath)" *ngIf="imageLoadErrorCount > 0">
      <img class="thumbnail" [src]="loadImageMedia(file)" [alt]="file?.originalFilename" [title]="file?.originalFilename" />
      <img *ngIf="file?.isVideo" [src]="constants.staticImages.icons.videoIcon" alt="Video icon" class="video-icon" />
    </div>
    <div
      *ngIf="imageLoadErrorCount === 0"
      class="thumbnail-container"
      appMediaViewer
      [viewItems]="uploadedFilesListReadonly"
      [allowThumbnail]="true"
      [enableZoom]="true"
      [isPlaying]="false"
      [enableRotate]="true"
      [imageIndex]="i"
      [fileKey]="'filepath'"
      (click)="onImageLoad(filename)"
    >
      <img class="thumbnail" [src]="file?.thumbnail ? file?.thumbnail : file?.filepath" [alt]="file?.originalFilename" [title]="file?.originalFilename" />
      <img *ngIf="file?.isVideo" [src]="constants.staticImages.icons.videoIcon" alt="Video icon" class="video-icon" />
    </div>
  </ng-container>
</div>

<ng-template #removeFileTemplate>
  <app-modal title="Remove file" [modalRef]="modalRef" [positiveResponse]="'Yes'" (onAction)="onConfirmRemove($event)">
    <p class="modal-body">Are you sure you want to remove?</p>
  </app-modal>
</ng-template>
