export const ROUTER_UTILS = {
  config: {
    base: {
      root: '/',
      dashboard: 'dashboard',
      report: {
        jobScheduleId: ':jobScheduleId',
        dailyReport: 'daily-report',
        TimeMaterialReport: 'time-material-report',
      },
    },
    navigation: {
      foreman: 'foreman',
      timecard: 'timecard',
      timecardEquipment: 'equipment',
      deliveries: 'deliveries',
      quantities: 'quantities',
      hourlyTrucking: 'hourly-trucking',
      equipments: 'equipments',
      observations: 'observations',
      forms: 'forms',
      openForm: 'open',
      jobScheduleId: ':jobScheduleId',
      admin: ':admin',
      scheduler: 'scheduler',
      formMasterID: ':formMasterID',
      jobDetail: 'jobDetails',
    },
    crewNavigation: {
      root: '/',
      crewMember: 'crew',
      dashboard: 'dashboard',
      jobScheduleId: ':jobScheduleId',
      timecard: 'timecard',
    },
    shopManagerNavigation: {
      root: 'shop-manager',
      dashboard: 'dashboard',
      addCostCode: 'addCostCode',
      timecard: 'timecard',
      addCrewEquipment: 'addCrewEquipment',
    },
    mechanicNavigation: {
      root: 'mechanic',
      welcome: 'welcome',
      dashboard: 'dashboard',
      jobs: 'jobs',
      equipment: 'equipment',
    },
    admin: {
      root: 'admin',
      welcome: 'welcome',
      dashboard: 'dashboard',
      jobs: 'jobs',
      equipment: 'equipment',
      wizard: 'wizard',
      workforce: 'workforce',
      phase: 'phase',
      review: 'review',
    },
    auth: {
      root: 'auth',
      login: 'login',
      forgotPassword: {
        root: 'forgot-password',
        init: 'init',
        finish: ':resetKey',
      },
    },
    settings: {
      root: 'settings',
      account: 'account',
      changePassword: 'change-password',
    },
    user: {
      root: 'users',
      profile: ':userId',
      profileDetails: 'profile-details',
    },
    crm: {
      root: 'crm',
    },
    transport: {
      root: 'transport',
    },
    inventory: {
      root: 'inventory',
    },
    pipeline: {
      root: 'pipeline',
    },
    administration: {
      root: 'admin',
      vendors: 'vendors',
      pipelineConfig: 'pipeline-config',
      dealers: 'dealers',
      users: {
        root: 'users',
        add: 'add',
        edit: ':userId',
      },
    },
    errorResponse: {
      notFound: '404',
      unauthorized: 'unauthorized',
    },
  },
};
