import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NotFoundModule } from '@shell/ui/not-found/not-found.module';
import { AuthorizedLayoutModule } from './ui/authorized-layout/authorized-layout.module';
import { FooterModule } from './ui/footer/footer.module';
import { HeaderModule } from './ui/header/header.module';
import { LayoutModule } from './ui/layout/layout.module';
import { SplashScreenComponent } from './ui/splash-screen/splash-screen.component';

const MODULES = [FooterModule, HeaderModule, LayoutModule, AuthorizedLayoutModule, NotFoundModule];

@NgModule({
  imports: [CommonModule, ...MODULES],
  exports: [RouterModule, ...MODULES, SplashScreenComponent],
  declarations: [SplashScreenComponent],
})
export class ShellModule {}
