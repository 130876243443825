import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCrudService } from '@core/services';
import { API_URL_UTIL } from '@core/utils';
import { Observable } from 'rxjs';
import { CompaniesListResponse } from 'src/app/@shared/models/add-employee.modal';
import { EventTask } from './../../@shared/models/user.modal';
import { AllScheduleUnscheduleJobsResponse, CompanyWiseJobsResponse, ScheduledJobRequest, SchuduleJobResponse } from './models/dashboard.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService extends BaseCrudService {
  getBaseAPIPath(): string {
    return API_URL_UTIL.foreman.root;
  }

  getUserTaskList(email: string, startDate: string): Observable<EventTask> {
    const params = new HttpParams().set('Email', email).set('startDate', startDate);
    return this.httpClient.get<EventTask>(`${API_URL_UTIL.foreman.foremanTaskList}`, { params });
  }

  getScheduledJobsForShopManager(date: string): Observable<EventTask> {
    const params = new HttpParams().set('startDate', date);
    return this.httpClient.get<EventTask>(`${API_URL_UTIL.shopManager.scheduledJobs}`, { params });
  }

  getAllCompanies(): Observable<CompaniesListResponse> {
    return this.httpClient.get<CompaniesListResponse>(`${API_URL_UTIL.foreman.getAllCompanies}`);
  }

  getCompanyWiseJobs(companyId: number, date: string): Observable<CompanyWiseJobsResponse> {
    const params = new HttpParams().set('companyId', companyId).set('date', date);
    return this.httpClient.get<CompanyWiseJobsResponse>(`${API_URL_UTIL.shopManager.jobsByCompany}`, { params });
  }

  getScheduledAndUnscheduledJobs(companyId: number, date?: string): Observable<AllScheduleUnscheduleJobsResponse> {
    let params;
    if (date) {
      params = new HttpParams().set('companyId', companyId).set('date', date);
    } else {
      params = new HttpParams().set('companyId', companyId);
    }
    return this.httpClient.get<AllScheduleUnscheduleJobsResponse>(`${API_URL_UTIL.shopManager.AllScheduleUnscheduleJobs}`, { params });
  }

  scheduleJob(body: ScheduledJobRequest): Observable<SchuduleJobResponse> {
    return this.httpClient.post<SchuduleJobResponse>(`${API_URL_UTIL.shopManager.scheduleJob}`, body);
  }

  getUserProfile(email: string): Observable<any> {
    const params = new HttpParams().set('email', email);
    return this.httpClient.get<any>(`${API_URL_UTIL.foreman.getEmployeeDetailsByEmail}`, { params });
  }

  getScheduledPhaseData(jobScheduleID: string): Observable<any> {
    return this.get<any>(null, `jobs/${jobScheduleID}/${API_URL_UTIL.foreman.phaseProgress}`);
  }

  getFormsListForSidePopup(jobScheduleID: string): Observable<any> {
    return this.get<any>(null, `${API_URL_UTIL.foreman.formsList}?JobScheduleId=${jobScheduleID}`);
  }
}
