import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@core/utils';
import { SidebarModalService } from '@sharedServices/sidebar-modal.service';

@Component({
  selector: 'app-custom-number-input',
  templateUrl: './custom-number-input.component.html',
  styleUrls: ['./custom-number-input.component.scss'],
})
export class CustomNumberInputComponent extends BaseComponent implements OnInit {
  regex!: RegExp | undefined;
  regexPattern = '^[0-9]{7}[.][0-9]{2}$';
  intialTypeOfValue = '';
  @Input() value: number | undefined;
  @Output() customChanged: EventEmitter<number | undefined> = new EventEmitter<number | undefined>();
  expression: Array<string> = [];
  arrExpression: Array<string> = [];
  strExpression = '';
  allowAddFormula = false;
  numbers = this.constants.numbers;
  isLayerBtnEnabled!: boolean;
  isOtherBtnEnabled!: boolean;

  constructor(private readonly sidebarModalService: SidebarModalService) {
    super();
  }

  ngOnInit(): void {
    this.intialTypeOfValue = !!this.value ? 'number' : 'undefined';
    this.regex = new RegExp(this.regexPattern);
  }

  addExpression(char: string) {
    if ((char === '.' && !this.strExpression.includes('.') && !this.regex?.test(this.strExpression)) || parseInt(this.strExpression ? this.strExpression : '0') < 1000000) {
      this.makeExpression(char);
    }
  }

  clearExpression(): void {
    this.expression = [];
    this.arrExpression = [];
    this.strExpression = '';
    this.allowAddFormula = false;
    this.isLayerBtnEnabled = false;
    this.isOtherBtnEnabled = false;
  }

  backspace(): void {
    this.expression.pop();
    this.arrExpression.pop();
    this.strExpression = this.arrExpression.join('');
    this.allowAddFormula = false;
    if (!this.arrExpression.length) {
      this.isLayerBtnEnabled = false;
      this.isOtherBtnEnabled = false;
    }
  }

  finalLoggedHours(): void {
    this.finalExpression();
    this.closeModal();
  }

  closeModal(): void {
    this.sidebarModalService.hideModal();
    const updatedValue = this.value || (this.intialTypeOfValue === 'number' ? 0 : undefined);
    this.customChanged.emit(updatedValue);
  }

  makeExpression(char: string): void {
    this.expression.push(char);
    this.arrExpression.push(char);
    this.strExpression += char;
    this.allowAddFormula = false;
  }

  finalExpression(): void {
    const floatExpression = parseFloat(this.strExpression).toFixed(2);
    if (floatExpression !== 'NaN') {
      this.value = parseFloat(floatExpression);
    }
  }
}
