import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { ServerErrorInterceptor } from './interceptors';
import { BaseComponent } from './utils/base.component';

@NgModule({
  declarations: [BaseComponent],
  imports: [CommonModule, HttpClientModule, ToastrModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
