import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { BaseComponent } from '@core/utils';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent extends BaseComponent {
  @Input() showFooter = true;
  @Input() modalRef: BsModalRef | undefined;
  @Input() positiveResponse?: string;
  @Input() enablePositiveResponse = true;
  @Input() title?: string;
  @Input() hideNegativeResponseBtn?: boolean;
  @Output() onAction: EventEmitter<boolean> = new EventEmitter();
  @Input() closeButtonName = 'No';
  constructor(private readonly modalService: BsModalService) {
    super();
  }

  modalResponse(selection: boolean): void {
    this.onAction.emit(selection);
    this.modalService.hide();
    this.hideModal();
  }

  hideModal(): void {
    this.modalRef?.hide();
  }
}
