import { Injectable } from '@angular/core';
import { BaseCrudService } from '@core/services';
import { API_URL_UTIL } from './../../../@core/utils/api-url.utils';

@Injectable({
  providedIn: 'root',
})
export class AddCostCodeService extends BaseCrudService {
  getBaseAPIPath(): string {
    return API_URL_UTIL.shopManager.root;
  }
}
