import { NgModule } from '@angular/core';
import { ClickOutsideDirective } from './click-outside.directive';
import { ImageIconDirective } from './image-icon.directive';
import { PasswordEyeDirective } from './password-eye.directive';
import { ShowLoaderOnApiCallDirective } from './show-loader-on-apicall.directive';
import { SortByDirective } from './sort-by.directive';
import { SortDirective } from './sort.directive';

const DIRECTIVES = [ClickOutsideDirective, ShowLoaderOnApiCallDirective, PasswordEyeDirective, ImageIconDirective, SortDirective, SortByDirective];

@NgModule({
  declarations: [...DIRECTIVES],
  exports: [...DIRECTIVES],
})
export class DirectivesModule {}
