import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faBars,
  faCheck,
  faCheckCircle,
  faChevronRight,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCirclePlus,
  faCircleXmark,
  faCity,
  faClock,
  faColumns,
  faCompress,
  faDownLeftAndUpRightToCenter,
  faEnvelope,
  faEnvelopeSquare,
  faExpand,
  faFile,
  faFileAlt,
  faFileUpload,
  faFolderClosed,
  faFolderOpen,
  faLocationArrow,
  faNoteSticky,
  faPause,
  faPencil,
  faPhoneAlt,
  faPlay,
  faSignOutAlt,
  faSignal,
  faSort,
  faSortDown,
  faSortUp,
  faSpinner,
  faTimes,
  faTrashCan,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faUsers,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

export const faIcons = {
  faCompress,
  faExpand,
  faSpinner,
  faCity,
  faColumns,
  faSignal,
  faFileAlt,
  faEnvelope,
  faPhoneAlt,
  faUser,
  faArrowLeft,
  faArrowRight,
  faCheckCircle,
  faSignOutAlt,
  faEnvelopeSquare,
  faSort,
  faSortDown,
  faSortUp,
  faAngleLeft,
  faAngleRight,
  faCirclePlus,
  faTrashCan,
  faPencil,
  faFileUpload,
  faCircleXmark,
  faCheck,
  faXmark,
  faChevronRight,
  faFile,
  faFolderOpen,
  faFolderClosed,
  faTimes,
  faCircleArrowLeft,
  faCircleArrowRight,
  faPlay,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faPause,
  faDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter,
  faUsers,
  faLocationArrow,
  faClock,
  faNoteSticky,
  faBars,
};
