import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppLoaderService {
  loaderEnabled = false;

  constructor() {}

  toggleLoader(isLoading: boolean): void {
    this.loaderEnabled = isLoading;
  }
}
