<ng-container *ngIf="!isCrewMember">
  <div class="row">
    <div class="col-lg-6">
      <ng-template [ngTemplateOutlet]="unselectedTemplate"></ng-template>
    </div>
    <div class="col-lg-6 side-table">
      <ng-template [ngTemplateOutlet]="selectedTemplate"></ng-template>
      <div class="float-end">
        <button class="btn btn-primary" (click)="updatePhases()">Update</button>
        <button class="btn btn-outline-danger ms-2" (click)="closeModal()">Cancel</button>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="isCrewMember">
  <div class="row">
    <div class="col-12 fix-width-unselected">
      <ng-template [ngTemplateOutlet]="selectedTemplate"></ng-template>
    </div>
  </div>
  <hr class="section-line my-4" />
  <div class="row mt-2">
    <div class="col-12 side-table">
      <ng-template [ngTemplateOutlet]="unselectedTemplate"></ng-template>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-12">
      <div class="float-end">
        <button class="btn btn-primary" (click)="updatePhases()">Update</button>
        <button class="btn btn-outline-danger ms-2" (click)="closeModal()">Cancel</button>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #unselectedTemplate>
  <div class="form-group mt-1">
    <label for="phase_search_1" class="col-form-label custom-label pb-3">Select Phase to add to the timecard</label>
    <div class="position-relative">
      <img class="search-icon" [src]="constants.staticImages.searchIcon" alt="search icon" />
      <input
        type="text"
        class="form-control ps-5 pt-1"
        name="phase_search_1"
        aria-describedby="phase_search_1"
        placeholder="Search"
        [(ngModel)]="searchString1"
        (keyup)="onPhaseSearch()"
      />
    </div>
    <div class="no-labor-block mt-4" *ngIf="!isDeliveries">
      <div class="d-flex flex-row flex-nowrap justify-content-start mt-2">
        <input class="form-check-input mx-3" type="checkbox" [ngModel]="noLabor" (change)="updateNoLaborData($event)" />
        <label for="No Labor" class="d-block m-t-2">Hide Phases with No Labor</label>
      </div>
    </div>
    <div class="pt-4">
      <div class="overflow-y-scroll card-height small-screen-card-height">
        <div class="border-bottom mb-2" *ngFor="let phase of phaseList; let i = index">
          <div class="py-3 px-2" [ngClass]="phase.hasLabor ? '' : 'phase-labor'">
            <div class="d-flex justify-content-between">
              <div class="table-cell">{{ phase?.itemDescriptionWithPhasecode }}</div>
              <div class="float-end pe-2">
                <div class="d-flex flex-row">
                  <!--  T & M Switch -->
                  <!-- <div class="form-check form-switch" *ngIf="enableTMSwitching">
                                      <input class="form-check-input input-switch" type="checkbox" role="switch" id="flexSwitchCheckChecked" [checked]="phase.isTANDM" (change)="toggleTMState(i)" [value]="phase.isTANDM" />
                                  </div> -->
                  <button class="border-0 ms-2" (click)="addPhaseToCurrentList(phase)" title="add">
                    <img [src]="constants.staticImages.plusIconForm" alt="plus icon" />
                  </button>
                </div>
              </div>
            </div>
            <div>
              <span class="custom-text">{{ phase?.description }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #selectedTemplate>
  <div class="form-group">
    <label for="phase_search_2" class="col-form-label custom-label pb-3">Phase on current timecard</label>
    <div class="position-relative" *ngIf="!isCrewMember">
      <img class="search-icon" [src]="constants.staticImages.searchIcon" alt="search icon" />
      <input
        type="text"
        class="form-control text-start ps-5"
        name="phase_search_2"
        aria-describedby="phase_search_2"
        placeholder="Search"
        [(ngModel)]="searchString2"
        (input)="filterCurrentPhases()"
      />
    </div>
    <div class="pt-4">
      <ng-container *ngFor="let phase of currentPhaseList">
        <div class="border-bottom py-3">
          <div class="d-flex justify-content-between">
            <div class="table-cell">
              {{ phase?.itemDescriptionWithPhasecode }}
              <span class="tm-check" *ngIf="phase?.isTANDM">&nbsp;T&M</span>
            </div>
            <div class="float-end">
              <div class="d-flex flex-row">
                <div class="form-check form-switch"></div>
                <button class="border-0" title="remove" (click)="showRemovePhaseFromCurrentListDialog(removePhaseTemplate, phase)">
                  <img [src]="constants.staticImages.crossIcon" alt="Cross icon" />
                </button>
              </div>
            </div>
          </div>
          <div>
            <span class="custom-text">{{ phase?.description }}</span>
          </div>
          <div>
            <span class="custom-text text-black" title="custom-description">{{ phase?.customDescription }}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #removePhaseTemplate>
  <app-modal title="Remove Phase" [modalRef]="modalRef" [positiveResponse]="'Yes'" (onAction)="removePhaseFromCurrentList($event)">
    <p class="modal-body">Are you sure you want to remove?</p>
  </app-modal>
</ng-template>
