import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularImageViewerModule } from 'angular-x-image-viewer';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { SidebarModule } from 'primeng/sidebar';
import { TooltipModule } from 'primeng/tooltip';
import { FontAwesomeIconsModule } from '../fontawesome.module';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import { MediaViewerDirective } from './media-viewer.directive';

@NgModule({
  declarations: [ImageViewerComponent, MediaViewerDirective],
  imports: [CommonModule, AngularImageViewerModule, SidebarModule, FontAwesomeIconsModule, TooltipModule, DropdownModule, FormsModule, CheckboxModule],
  exports: [MediaViewerDirective],
})
export class MediaViewerModule {}
