export const API_URL_UTIL = {
  account: {
    root: 'account',
    loginInit: 'login/init',
    loginFinish: 'login/finish',
    loginWithEmailPassword: 'authenticate',
    forgotPasswordInit: 'account/reset-password/init',
    forgotPasswordFinish: 'account/reset-password/finish',
    refreshAccessToken: 'token/refresh',
  },
  settings: {
    changePassword: 'account/change-password',
  },
  microsoftLogin: {
    userDetails: 'https://graph.microsoft.com/v1.0/me',
  },
  foreman: {
    root: 'Foreman',
    getEmployeeDetailsByEmail: 'Foreman/GetEmployeeDetailsByEmail',
    foremanTaskList: 'Foreman/GetForemanJobList',
    getCrewPhaseDetailsByJobScheduleId: 'GetCrewPhaseDetailsbyJobScheduleId',
    saveJobScheduleLoggedHours: 'Foreman/SaveJobScheduleLoggedHours',
    getAllCompanies: 'Foreman/GetAllCompanies',
    getAllEmployeesByCompanyId: 'Foreman/GetAllEmployeesByCompanyId',
    saveCrewByJobScheduleId: 'Foreman/SaveCrewByJobScheduleId',
    getAllActivePhasesByJobScheduleId: `Foreman/GetAllActivePhasesByJobScheduleId`,
    savePhasesByJobScheduleId: 'Foreman/SavePhasesByJobScheduleId',
    jobSchedule: 'GetJobScheduleDetail',
    customDescription: 'customdescription',
    stoneConcreteHours: 'StoneConcreteBidReceivedHours',
    getEquipmentCategorywiseList: 'GetEquipmentCategorywiseList',
    getCrewPerDiemDetails: 'Foreman/GetCrewPerDiemDetails',
    saveJobScheduleCrewPerDiem: 'Foreman/SaveJobScheduleCrewPerDiem',
    deletePerDiemApi: 'Foreman/PerDiemData',
    saveMarkEmployeeAsNoShow: 'Foreman/MarkEmployeeAsNoShow',
    materialList: 'GetMaterialsListByJobscheduleId',
    vendorAdMixtureRejectReasonList: 'GetVenorAdmixtureRejectedList',
    saveStoneMaterialList: 'SaveStoneMaterialsList',
    saveConcreteMaterialList: 'SaveConcreteMaterialList',
    saveEquipmentsByJobScheduleId: 'SaveEquipmentsByJobScheduleId',
    getStoneConcreteReceivedHistory: 'StoneConcreteReceivedHistory',
    getHourlyTruckingDetails: 'HourlyTruckingDetails',
    addLogHours: 'PhaseHourlyTrucking',
    stoneMaterialDetail: 'StoneMaterialDetail',
    concreteMaterialDetail: 'ConcreteMaterialDetail',
    addSingleTAndMPhase: 'AddSingleTAndMPhase',
    jobs: 'jobs',
    quantities: 'quantities',
    updateQuantities: 'TodaysQuantities',
    getEquipmentsByJobScheduleId: 'JobEquipment',
    updateEquipmentsByJobScheduleId: 'JobEquipment',
    updateEquipmentsMeterByJobScheduleId: 'JobEquipmentMeter',
    uploadFile: 'UploadFileToSharePoint',
    observationData: 'ObservationData',
    addObservation: 'JobObservation',
    dailyReportDetails: 'dailyreportdetails',
    dailyTAndMReportDetails: 'tandmreportdetails',
    phaseProgress: 'phasesprogress',
    saveJobScheduleLoggedHoursUrl: 'saveJobScheduleLoggedHours',
    formsList: 'FormsList',
    sentUnsentFormsList: 'SentUnsentFormsList',
    removeForm: 'Deleteform',
    downloadForm: 'DownloadForm',
    savedSentUnsentForm: 'SavedSentUnsentForm',
    sendUnsentForm: 'SendUnsentForms',
    deliveryLeftApi: 'Foreman/AllDeliveriesActivePhasesByJobScheduleId',
    deliverySaveApi: 'Foreman/DeliveriesPhasesByJobScheduleId',
    deliveryRightApi: 'Foreman/CrewDeliveriesPhaseDetailsbyJobScheduleId',
    quantitiesHistory: 'QuantitiesHistory',
    customTAndM: {
      addCustomTandM: 'AddCustomTMphase',
      deleteCustomTAndM: 'phase',
      deleteCustomTAndMKey2: 'timecard',
    },
    employeeWeeklyHoursHistory: 'EmployeeWeeklyHoursHistory',
    allActivePhasesandSelectedPhaseByJobScheduleId: 'AllActivePhasesandSelectedPhaseByJobScheduleId',
    admin: {
      root: 'JobSchedule',
      allJobScheduleDashboardDetails: 'AllJobScheduleDashboardDetails',
      allAssignedEmployeesForDay: 'AllAssignedEmployeesForDay',
      generalOrForemanNotes: 'GeneralOrForemanNotes',
      sendDailyJobScheduleByEmail: 'SendDailyJobScheduleByEmail',
      sendDailyJobScheduleByTextMessage: 'SendDailyJobScheduleByTextMessage',
      jobscheduledetailsInScheduler: 'JobscheduledetailsinScheduler',
      submitJobSchedule: 'SubmitJobSchedule',
      jobScheduleActions: 'JobScheduleActions',
      deleteJobSchedule: 'DeleteJobSchedule',
      jobs: 'jobs',
      copyScheduleJob: 'CopyScheduleJob',
    },
    updateEmployeeNamePreference: 'UpdateEmployeeNamePreference',
  },
  crew: {
    root: 'Crew',
    job: 'jobs',
    crewMember: 'CrewJobList',
    crewLoggeddhours: 'CrewLoggedhours',
    phaseEquipmentList: 'PhaseEquipmentList',
    loggedHours: 'LoggedHours',
  },
  shopManager: {
    root: 'ShopManager',
    costCode: 'CostCodes',
    jobsByCompany: 'CommanUser/AllJobsByCompanyId',
    scheduleJob: 'CommanUser/Schedulejob',
    scheduledJobs: 'ShopManager/ShopManagerJobList',
    SendEmailsTAndMReport: 'SendEmailforTandMReport',
    EquipmentCrewMembers: 'EquipmentCrewMembers',
    EquipmentLoggedHours: 'EquipmentLoggedHours',
    AllScheduleUnscheduleJobs: 'CommanUser/AllScheduleUnscheduleJobs',
  },
  admin: {
    CompanyItem: 'CommanUser/AllContractItemsByCompanyIdAndContractId',
    activePhaseList: 'JobSchedule/AllActivePhasesByFilter',
    JobscheduledetailsinScheduler: 'JobSchedule/JobscheduledetailsinScheduler',
    saveJobEmployeephasewise: 'JobSchedule/JobEmployeephasewise',
  },
  mechanic: {
    root: 'Mechanic',
    totalLoggedHours: 'MechanicLoggedHours',
  },
};
