import { Component, Input } from '@angular/core';
import { BaseComponent } from '@core/utils';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent extends BaseComponent {
  @Input() pageTitle!: string;

  constructor() {
    super();
  }
}
