export const Constants = {
  applicationLogoUrl: 'assets/images/logo.svg',
  applicationLogoDarkUrl: 'assets/images/logo.svg',
  regexForOnlyNumberCheck: /^\d+$/,
  environmentsForErrorTracing: ['localhost', 'https://dev.baseprojectangular.thesunflowerlab.com/'],
  splashScreenTimeout: 200,
  staticImages: {
    phone: 'assets/images/phone-black-border.svg',
    edit: 'assets/images/editIcon.svg',
    email: 'assets/images/email-black-background.svg',
    circleWhite: 'assets/images/circle-white.svg',
    circleBlue: 'assets/images/plus-blue.svg',
    TmReportHover: 'assets/images/TmReport.svg',
    JobReportImgHover: 'assets/images/reportIconHover.svg',
    FormsImageHover: 'assets/images/FormReportHover.svg',
    ObservationsImageHover: 'assets/images/ObservationHover.svg',
    equipmentImageHover: 'assets/images/EquimentHover.svg',
    quantitiesImageHover: 'assets/images/QuantitiesHover.svg',
    timecardIconHover: 'assets/images/timecardCalender.svg',
    deliveryIconHover: 'assets/images/DelivaryHover.svg',
    arrowWhite: 'assets/images/arrow-white-space.svg',
    backspaceThinWhite: 'assets/images/BackspaceWhiteThin.svg',
    backspaceThinBlack: 'assets/images/backspaceLowThin.svg',
    accordionArrow: 'assets/images/down-arrow-arcadian.svg',
    truckImageSvg: 'assets/images/truckImage.svg',
    dollarIcon: 'assets/images/dollor-icon.svg',
    emailImage: 'assets/images/emailImage.svg',
    phoneLogo: 'assets/images/phonelogo.svg',
    infoIcon: 'assets/images/infoDetails.svg',
    searchIcon: 'assets/images/search@1.5x.svg',
    arrowButton: '/assets/images/arrow-right.svg',
    plusIcon: 'assets/images/plus-circle1.5x.svg',
    plusIconForm: 'assets/images/file-plus1.5x.svg',
    crossIcon: '/assets/images/cross-icon.svg',
    loggedHours: 'assets/images/clock1.5x.svg',
    timeCardImage: 'assets/images/Group 20/Group 3/Group 2@1.5x.svg',
    DeliveriesCardImage: '/assets/images/Group 3 Copy/Group 4@1.5x.svg',
    deliveriesHoverImage: '/assets/images/delivery_hover.svg',
    HourlyTruckingImage: 'assets/images/Group 3 Copy 2/Group 2@1.5x.svg',
    HourlyTruckingImageHover: 'assets/images/Group 3 Copy 2/Group 2@1.5x-hover.svg',
    QuantitiesImage: 'assets/images/Group 3 Copy 3/layers (1)@1.5x.svg',
    truckImage: 'assets/images/truck_image.png',
    EquipmentImage: '/assets/images/Group 3 Copy 4/truck@1.5x.svg',
    ObservationsImage: '/assets/images/Group 3 Copy 5/Group 13@1.5x.svg',
    FormsImage: '/assets/images/Group 3 Copy/file-text (1)@1.5x.svg',
    JobReportImg: '/assets/images/Group 3 Copy 6/Group 18@1.5x.svg',
    TMReportImage: '/assets/images/Group 3 Copy 7/file-text (1)@1.5x.svg',
    noCheckImage: '/assets/images/no-check.svg',
    rightCheckboxImage: '/assets/images/Right.svg',
    icons: {
      scheduleWhite: '/assets/images/Schedule_White.svg',
      schedule: '/assets/images/Schedule.svg',
      threeDot: 'assets/svg/threeDot.svg',
      calender: 'assets/images/calendar (1)@1.5x.svg',
      taskTick: '/assets/images/Group 4@1.5x.svg',
      rightTick: '/assets/images/Group 4@1.5x.svg',
      crmActive: 'assets/images/icons/CRM-Active.svg',
      crmHover: 'assets/images/icons/CRM-Hover.svg',
      adminActive: 'assets/images/icons/Administrator-Active.svg',
      adminHover: 'assets/images/icons/Administrator-Hover.svg',
      dashboardActive: 'assets/images/icons/Dashboard-Active.svg',
      dashboardHover: 'assets/images/icons/Dashboard-Hover.svg',
      inventoryActive: 'assets/images/icons/Inventory-Active.svg',
      inventoryHover: 'assets/images/icons/Inventory-Hover.svg',
      transportActive: 'assets/images/icons/Transport-Active.svg',
      transportHover: 'assets/images/icons/Transport-Hover.svg',
      pipelineActive: 'assets/images/icons/Pipeline-Active.svg',
      pipelineHover: 'assets/images/icons/Pipeline-Hover.svg',
      uploadArrow: 'assets/images/icons/Upload-Arrow.svg',
      uploadImage: 'assets/images/icons/Upload-Image.svg',
      viewIcon: 'assets/images/icons/View-Icon.svg',
      deleteIcon: 'assets/images/icons/Delete-Icon.svg',
      deliveryTruck: 'assets/images/icons/Delivery-Truck.svg',
      addNew: 'assets/images/icons/Add-New.svg',
      add: 'assets/images/icons/Add.svg',
      bellNotification: 'assets/images/icons/Bell-Notification.svg',
      download: 'assets/images/icons/Download.svg',
      edit: 'assets/images/icons/Edit.svg',
      exportFile: 'assets/images/icons/Export-File.svg',
      key: 'assets/images/icons/key.svg',
      lockPassword: 'assets/images/icons/Lock-Password.svg',
      loginUser: 'assets/images/icons/Login-User.svg',
      timeClock: 'assets/images/icons/Time-Clock.svg',
      trashIcon: 'assets/images/delete-2-svgrepo-com.svg',
      trashIconRed: 'assets/svg/edit.svg',
      editTrash: 'assets/svg/trash.svg',
      downIcon: './assets/images/chevrons-right@1.5x.svg',
      disableCalender: 'assets/images/calender-disable.svg.svg',
      taskWarning: '/assets/images/info.svg',
      videoIcon: '/assets/svg/video.svg',
      nextIcon: 'assets/svg/next.svg',
    },
  },
  roles: {
    foreman: 'FMN',
    labour: 'LAB',
    mechanic: 'MECH',
    shopAssist: 'SHOPASST',
    ShopSupervisor: 'SHOPSUP',
  },
  users: {
    foreman: 'foreman',
    labour: 'crew',
    mechanic: 'mechanic',
    shopManager: 'shop-manager',
  },
  sidebarConfig: {
    visible: true,
    position: 'right',
    blockScroll: false,
    showCloseIcon: true,
    transitionOptions: '150ms',
    closeOnEscape: true,
    baseZIndex: 1,
  },
  globalModalConfig: {
    class: 'right',
  },
  sidebarModalConfig: {
    class: 'right',
  },
  sidebarSmModalConfig: {
    class: 'right modal-sidebar-sm',
  },
  calculatorModalConfig: {
    class: 'right modal-sidebar-calculator',
  },
  modalSidebarFull: {
    class: 'right modal-sidebar-full',
  },
  historyWeekConfig: {
    class: 'week-history right',
  },
  addEmployee: {
    addEmployeePageNo: 10,
    addEmployeeMaxPageNo: 2,
  },
  bsConfig: {
    isAnimated: true,
    containerClass: 'custom-today-class',
    outsideClick: true,
    placement: 'bottom',
    dateInputFormat: 'ddd, MMMM DD, YYYY',
    showWeekNumbers: false,
    showTodayButton: false,
  },
  formDatePicker: {
    isAnimated: true,
    containerClass: 'custom-today-class',
    outsideClick: true,
    placement: 'bottom',
    dateInputFormat: 'MM/DD/YYYY',
    showWeekNumbers: false,
    showTodayButton: false,
  },
  schedularDatePicker: {
    isAnimated: true,
    containerClass: 'custom-today-class',
    outsideClick: true,
    placement: 'bottom',
    dateInputFormat: 'ddd, MMMM DD, YYYY',
    showWeekNumbers: false,
    showTodayButton: false,
  },
  unit: {
    tons: 'Tons',
    CY: 'CY',
    noUnit: 'Qty',
  },
  validation: {
    decimal: '[0-9]*',
    positiveNumber: 'd+',
    email:
      /^(("[\w-\s]+")|([\w-\+]+(?:\.[\w-\+]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/,
  },
  numbers: {
    NUM_1: '1',
    NUM_2: '2',
    NUM_3: '3',
    NUM_4: '4',
    NUM_5: '5',
    NUM_6: '6',
    NUM_7: '7',
    NUM_8: '8',
    NUM_9: '9',
    NUM_0: '0',
    DEC: '.',
  },
  keys: {
    ESC: 'esc',
  },
  pdfSetting: {
    orientation: 'portrait',
    format: 'a4',
    precision: '4',
    compress: true,
  },
  tAndMReportSetting: {
    signatureText:
      'The signatory below acknowledges that the quantities of materials, labor, equipment, and other items contained in the Work Order were used for the work and Project identified above and that such quantities are reasonable for the work performed.',
    Signatory: `By OWNER/GC:__________________________________________________________________________________`,
    tAndMTitle: 'Daily Time & Material (T & M) Report',
    observation: 'Observation',
    reportHeader: {
      metaHeader: ['Job Name', 'Date', 'Foreman', 'Company'],
      dataHeader: ['Employee', 'Phase', 'Hours'],
      labor: ['Employee', 'Phase', 'Hours'],
      stoneDeliveries: ['Phase', 'Material', 'Notes', 'Material Notes', 'Tonnage'],
      concreteDeliveries: ['Phase', 'Material', 'CY', 'Mixtures', 'Demurrage', 'Notes', 'Detail Notes'],
      hourlyTrucking: ['Phase', 'Received Hours', 'Notes'],
      Observation: [`Today's Delay on Job`, 'Additional Details', 'Images'],
      Notes: ['Notes'],
    },
  },
  dailyReportSetting: {
    dailyReportTitle: 'Daily Report',
    reportHeader: {
      metaHeader: ['Job Name', 'Date', 'Foreman', 'Company'],
    },
    body: {
      labourDataTitle: 'Labor',
      labourData: ['Employee', 'Phase', 'Hours'],
      perDiemTitle: 'Per Diem ($)',
      perDiemHeader: ['Employee', 'Per Diem Amount ($)', 'Notes'],
      EquipmentTitle: 'Equipment',
      EquipmentData: ['Equipment', 'Company', 'Today’s Meter'],
      EquipmentContract: ['Equipment', 'Phase', 'Company', 'Hours'],
      cement: 'Aggregates/Cement Deliveries',
      cementData: ['Phase', 'Material', 'Tonnage'],
      cementNotesData: ['Phase', 'Notes', 'Material Notes'],
      concreteDeliveriesTitle: 'Concrete Deliveries',
      concreteDeliveriesData: ['Phase', 'Material', 'CY', 'Mixtures', 'Demurrage'],
      concreteDeliveriesNotes: ['Phase', 'Notes', 'Material Notes'],
      hourlyTruckingTitle: 'Hourly Trucking',
      hourlyTruckingData: ['Phase', 'Received Hours', 'Notes'],
      QuantitiesTitle: 'Quantities',
      quantitiesData: ['Phase', 'Today’s Qty', 'Total Qty'],
      ObservationTitle: 'Observations',
      observationData: ['Working Condition', 'Images Url'],
      noShowTitle: 'No Show',
      noShowData: ['Employee'],
    },
  },
  noDataFound: 'No Data Found',
  payRollUrl: 'https://www.trimor.com/pay',
  toasterResponsive: {
    toaster: { '920px': { width: '100%', right: '0', left: '0' } },
  },
  warningMessage: {
    equipment: { severity: 'info', detail: 'Please include at least one phase for equipment' },
    employee: { severity: 'info', detail: 'Please include at least one phase for  employee' },
    observation: { detail: "It's look like you don't have access to view file, please login in share point or contact the admin." },
    durationValidate: { detail: 'You have uploaded more than 30s duration video, so the video has been discarded' },
  },
  regaxOnlyToConvertFileExt: /\.[^/.]+$/,
  canvasContextError: 'Canvas context not available.',
  videoLoadingError: 'Error loading the video.',
  urlRedirectNotLoad: 'http://',
  allOptionToDropdown: { companyId: 0, name: 'All Companies', scheduleColor: '' },
  scheduleDropdownOptions: [
    { name: 'All', id: 0 },
    { name: 'Scheduled', id: 1 },
    { name: 'Not Scheduled', id: 2 },
  ],
  jobDetail: {
    unmark: 'Unmark',
    mark: 'Mark',
    cancelJob: 'Cancel Job',
    unCancelJob: 'Uncancel Job',
    cancelText: 'Cancel',
    unCancelText: 'UnCancel',
  },
};
