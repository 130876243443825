import { takeUntil } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { API_URL_UTIL, BaseComponent } from '@core/utils';
import { TimeCardService } from '@pages/time-card/time-card.service';
import { getFormattedDate } from '@core/utils/date-format.utils';
import { EmpWeekTotalHoursData } from '../../models/add-employee.modal';
import { ApiResponse } from '../../models';

@Component({
  selector: 'app-employee-hours-History',
  templateUrl: './employee-hours-history.component.html',
  styleUrls: ['./employee-hours-history.component.scss'],
})
export class EmployeeHoursHistoryComponent extends BaseComponent implements OnInit {
  @Input() crewDetail!: EmpWeekTotalHoursData;
  @Input() employeeId!: number | undefined;
  @Input() startDate!: Date;

  constructor(private readonly timeCardService: TimeCardService) {
    super();
  }

  ngOnInit(): void {
    this.employeeWeeklyHoursHistortGet();
  }

  employeeWeeklyHoursHistortGet(): void {
    this.timeCardService
      .get<ApiResponse<EmpWeekTotalHoursData>>(this.employeeWeeklyHoursHistoryUrl())
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: any) => {
          this.crewDetail = data.data;
        },
      });
  }

  employeeWeeklyHoursHistoryUrl(): string {
    return `${API_URL_UTIL.foreman.employeeWeeklyHoursHistory}?EmployeeId=${this.employeeId}&JobStartDate=${getFormattedDate(this.startDate)}`;
  }
}
