import { AfterViewChecked, Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appImageIconSrc]',
})
export class ImageIconDirective implements OnInit, AfterViewChecked {
  @Input() hoverImgSrc!: string;
  @Input('appImageIconSrc') imgSrc!: string;
  private isHovering = false;

  constructor(private readonly el: ElementRef<HTMLImageElement>, private readonly renderer: Renderer2) {}

  ngOnInit(): void {
    this.createImageElement();
    this.setImageSrc();
  }

  ngAfterViewChecked(): void {
    this.setImageSrc();
  }

  @HostListener('mouseover') onMouseOver() {
    this.isHovering = true;
    this.renderer.setAttribute(this.imageElement, 'src', this.hoverImgSrc || this.imgSrc);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.isHovering = false;
    this.setImageSrc();
  }

  setImageSrc() {
    if (!this.isHovering) {
      this.renderer.setAttribute(this.imageElement, 'src', this.el.nativeElement.classList.contains('active') ? this.hoverImgSrc || this.imgSrc : this.imgSrc);
    }
  }

  get imageElement(): HTMLImageElement {
    return this.el.nativeElement.getElementsByTagName('img')[0];
  }

  createImageElement() {
    const img = this.renderer.createElement('img');
    this.renderer.appendChild(this.el.nativeElement, img);
  }
}
