import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { PermissionGuard } from '@core/guards/permission.guard';
import { ROUTER_UTILS } from '@core/utils/router.utils';
import { NotFoundComponent } from '@shell/ui/not-found/not-found.component';

const APP_ROUTES: Routes = [
  {
    path: '',
    children: [],
    canActivate: [MsalGuard],
  },
  {
    path: `${ROUTER_UTILS.config.navigation.foreman}/${ROUTER_UTILS.config.errorResponse.unauthorized}`,
    pathMatch: 'full',
    loadChildren: async () => (await import('@pages/un-authorized/un-authorized.module')).UnAuthorizedModule,
  },
  {
    path: `${ROUTER_UTILS.config.navigation.foreman}/${ROUTER_UTILS.config.base.dashboard}`,
    loadChildren: async () => (await import('@pages/dashboard/dashboard.module')).DashboardModule,
    canActivate: [MsalGuard, PermissionGuard],
  },
  {
    path: `${ROUTER_UTILS.config.navigation.foreman}/${ROUTER_UTILS.config.navigation.jobScheduleId}/${ROUTER_UTILS.config.navigation.forms}`,
    loadChildren: async () => (await import('@pages/app-forms/app-forms.module')).AppFormsModule,
    canActivate: [MsalGuard, PermissionGuard],
  },
  {
    path: `${ROUTER_UTILS.config.navigation.foreman}/${ROUTER_UTILS.config.navigation.admin}}`,
    loadChildren: async () => (await import('./pages/Admin/admin-navigation/admin-navigation.module')).AdminNavigationModule,
    canActivate: [MsalGuard, PermissionGuard],
  },
  // {
  //   path: `${ROUTER_UTILS.config.shopManagerNavigation.root}/${ROUTER_UTILS.config.shopManagerNavigation.dashboard}`,
  //   loadChildren: async () => (await import('@pages/dashboard/dashboard.module')).DashboardModule,
  //   canActivate: [MsalGuard, PermissionGuard],
  // },
  // {
  //   path: `${ROUTER_UTILS.config.mechanicNavigation.root}`,
  //   loadChildren: async () => (await import('@pages/mechanic/mechanic.module')).MechanicModule,
  //   canActivate: [MsalGuard, PermissionGuard],
  // },
  // {
  //   path: `${ROUTER_UTILS.config.shopManagerNavigation.root}/${ROUTER_UTILS.config.shopManagerNavigation.addCostCode}`,
  //   loadChildren: async () => (await import('./pages/shopmanger-timecard-sidebar/shopmanger-timecard-sidebar.module')).ShopmangerTimecardSidebarModule,
  //   canActivate: [MsalGuard, PermissionGuard],
  // },
  {
    path: ROUTER_UTILS.config.navigation.foreman,
    loadChildren: async () => (await import('./pages/navigation-bar/navigation-bar.module')).NavigationBarModule,
    canActivate: [MsalGuard, PermissionGuard],
  },
  // {
  //   path: ROUTER_UTILS.config.shopManagerNavigation.root,
  //   loadChildren: async () => (await import('./pages/navigation-bar/navigation-bar.module')).NavigationBarModule,
  //   canActivate: [MsalGuard, PermissionGuard],
  // },
  // {
  //   path: ROUTER_UTILS.config.crewNavigation.crewMember,
  //   loadChildren: async () => (await import('./pages/crew-navigation/crew-navigation.module')).CrewNavigationModule,
  //   canActivate: [MsalGuard, PermissionGuard],
  // },
  {
    path: ROUTER_UTILS.config.settings.root,
    loadChildren: async () => (await import('@pages/settings/settings.module')).SettingsModule,
    canActivate: [MsalGuard],
  },
  {
    path: ROUTER_UTILS.config.user.profileDetails,
    loadChildren: async () => (await import('@pages/profile-page/profile-page.module')).ProfilePageModule,
    canActivate: [MsalGuard],
  },
  {
    path: `${ROUTER_UTILS.config.navigation.foreman}/${ROUTER_UTILS.config.base.report.dailyReport}/${ROUTER_UTILS.config.base.report.jobScheduleId}`,
    loadChildren: async () => (await import('@pages/daily-report/daily-report.module')).DailyReportModule,
    canActivate: [MsalGuard, PermissionGuard],
  },
  {
    path: `${ROUTER_UTILS.config.navigation.foreman}/${ROUTER_UTILS.config.base.report.TimeMaterialReport}/${ROUTER_UTILS.config.base.report.jobScheduleId}`,
    loadChildren: async () => (await import('@pages/time-material-report/time-material-report.module')).TimeMaterialReportModule,
    canActivate: [MsalGuard, PermissionGuard],
  },
  {
    path: '**',
    loadChildren: async () => (await import('@shell/ui/not-found/not-found.module')).NotFoundModule,
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
