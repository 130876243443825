<ng-container>
  <div class="media-wrapper">
    <p-sidebar [(visible)]="imageViewerVisibility" [fullScreen]="true" [blockScroll]="true">
      <div class="modal-title">
        <h4>{{ title }}</h4>
        <fa-icon [icon]="faIcons.faTimes" (click)="onCancel()"></fa-icon>
      </div>
      <div class="row m-0 play-btn" *ngIf="isPlaying && mediaList.length > 1">
        <fa-icon
          [icon]="faIcons.faPlay"
          *ngIf="!isPlaying"
          class="icon"
          (click)="playPauseSlideshow()"
          tooltipPosition="bottom"
          [showDelay]="250"
          pTooltip="Play slideshow"
        ></fa-icon>
        <fa-icon
          [icon]="faIcons.faPause"
          *ngIf="isPlaying"
          class="icon"
          (click)="playPauseSlideshow()"
          tooltipPosition="bottom"
          [showDelay]="250"
          pTooltip="Pause slideshow"
        ></fa-icon>
      </div>

      <div class="media-container">
        <fa-icon
          [icon]="faIcons.faAngleDoubleLeft"
          class="icon prev"
          tooltipPosition="bottom"
          pTooltip="Previous media"
          (click)="nextPrevElement('prev')"
          *ngIf="isReady && mediaList.length > 1 && imageIndex !== 0"
        >
        </fa-icon>
        <div [ngClass]="mediaList.length <= 1 ? 'img-video mt-80' : 'img-video'" #imageContainer>
          <angular-image-viewer
            class="fade-in-image"
            *ngIf="isReady && mediaList[imageIndex].mediaType === 'Image'"
            [src]="images"
            [config]="imageViewerConfig"
            [(index)]="imageIndex"
          >
          </angular-image-viewer>
          <div class="video-player" *ngIf="isReady && mediaList[imageIndex].mediaType === 'Video'">
            <video
              width="650"
              controls
              [autoplay]="isPlaying ? false : true"
              autoplay
              disablePictureInPicture
              controlsList="noplaybackrate nodownload"
              subtitles="test"
              descriptions="test"
              [src]="mediaList[imageIndex][fileKey]"
            >
              <source [src]="mediaList[imageIndex][fileKey]" type="video/mp4" />
            </video>
          </div>
        </div>
        <fa-icon
          [icon]="faIcons.faAngleDoubleRight"
          class="icon next"
          (click)="nextPrevElement('next')"
          *ngIf="isReady && mediaList.length > 1 && imageIndex !== mediaList.length - 1"
          tooltipPosition="bottom"
          [showDelay]="250"
          pTooltip="Next media"
        >
        </fa-icon>
        <div class="footer" *ngIf="allowThumbnail && mediaList.length >= 1">
          <div class="thumbnail-dropdown">
            <p-dropdown [options]="thumbnailSize" [(ngModel)]="selectedThumbnailSize" *ngIf="allowThumbnail && allowThumbnailSize" (onChange)="onSelectThumbnailSize($event)">
            </p-dropdown>
          </div>
          <div class="d-flex media-thumbnail">
            <div
              *ngFor="let m of mediaList; let i = index"
              #element
              (click)="loadCurrentMedia(i)"
              [ngClass]="{
                'x-small': selectedThumbnailSize.name === 'Extra Small',
                small: selectedThumbnailSize.name === 'Small',
                medium: selectedThumbnailSize.name === 'Medium',
                large: selectedThumbnailSize.name === 'Large'
              }"
              class="thumbnail-content"
            >
              <img [alt]="m?.fileName" [src]="m.thumbnail ? m.thumbnail : m.filepath" [ngClass]="{ active: imageIndex === i }" class="image-thumbnail" />
              <img *ngIf="m.mediaType === 'Video'" [src]="constants.staticImages.icons.videoIcon" alt="Video icon" class="video-icon" />
            </div>
          </div>
          <div class="total-tbn">{{ imageIndex + 1 }} / {{ images.length }}</div>
        </div>
      </div>
    </p-sidebar>
  </div>
</ng-container>
