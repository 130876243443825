import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { BaseComponent } from '@core/utils';
import { getFormattedDate } from '@core/utils/date-format.utils';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { SidebarModalService } from 'src/app/@shared/services/sidebar-modal.service';
import { TimecardApiResponse } from '../../models';
import { HttpStatus } from '../../models/add-employee.modal';
import { AddPhaseResponse, PhaseDetails } from '../../models/add-phase.modal';
import { UserDataService } from '../../services/user-data.service';
import { ToasterService } from './../../../@core/services/toaster.service';
import { AddPhaseService } from './add-phase.service';
@Component({
  selector: 'app-add-phase',
  templateUrl: './add-phase.component.html',
  styleUrls: ['./add-phase.component.scss'],
})
export class AddPhaseComponent extends BaseComponent implements OnInit {
  @Input() isDeliveries = false;
  @Input() isConcrete = false;
  patchCompanyId!: number;
  user!: string;
  searchString1 = '';
  searchString2 = '';
  phaseToRemove!: PhaseDetails;
  phaseList: Array<PhaseDetails> = [];
  currentPhaseList: Array<PhaseDetails> = [];
  _currentPhaseList: Array<PhaseDetails> = [];
  modalRef!: BsModalRef | undefined;
  noLabor = true;
  @Input() isCrewMember!: boolean;
  @Input() jobScheduleId!: string;
  @Input() jobScheduleDate!: Date;
  @Input() enableTMSwitching!: boolean;
  @Output() onModalClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  private searchSubject = new Subject<string>();

  constructor(
    private readonly addPhaseService: AddPhaseService,
    private readonly userDataService: UserDataService,
    private readonly toasterService: ToasterService,
    private readonly sidebarModalService: SidebarModalService,
    private readonly modalService: BsModalService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.user = this.userDataService.getLoginUser();
    this.getPhaseList();
    this.getCurrentPhaseList();
    this.startListeningPhaseSearch();
  }

  startListeningPhaseSearch(): void {
    this.searchSubject.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe(() => {
      this.getPhaseList();
    });
  }

  onPhaseSearch(): void {
    this.searchSubject.next(this.searchString1);
  }

  getPhaseList(): void {
    this.addPhaseService.getPhaseList(this.jobScheduleId, this.searchString1, this.isConcrete, this.isDeliveries, this.noLabor).subscribe((phaseList: AddPhaseResponse) => {
      this.phaseList = phaseList.data;
      this.patchCompanyId = phaseList?.data[0]?.companyId as number;
      this.phaseList?.forEach((data) => {
        data.isTANDM = false;
      });
    });
  }

  getCurrentPhaseList(): void {
    this.addPhaseService
      .getCurrentPhaseList(this.jobScheduleId, getFormattedDate(this.jobScheduleDate), !this.enableTMSwitching, this.isDeliveries, this.isConcrete)
      .subscribe((res: TimecardApiResponse) => {
        this.currentPhaseList = res.data?.phaseDetailsList?.length ? res.data.phaseDetailsList : [];
        this._currentPhaseList = res.data?.phaseDetailsList?.length ? res.data?.phaseDetailsList : [];
      });
  }

  toggleTMState(phaseIndex: number): void {
    this.phaseList[phaseIndex].isTANDM = !this.phaseList[phaseIndex].isTANDM;
  }

  updatePhases(): void {
    this._currentPhaseList?.forEach((element) => {
      element.worksFor = this.patchCompanyId;
    });
    this.addPhaseService
      .updatePhase(this.jobScheduleId, this.user, this._currentPhaseList, getFormattedDate(this.jobScheduleDate), !this.enableTMSwitching, this.isDeliveries, this.isConcrete)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (updatePhase: HttpStatus) => {
          this.toasterService.success(updatePhase.message);
          this.onModalClose.emit(true);
          this.sidebarModalService.hideModal();
        },
        error: (employeeUpdate: HttpStatus) => {
          this.toasterService.error(employeeUpdate.message);
        },
      });
  }

  filterCurrentPhases(): void {
    this.currentPhaseList = [...this._currentPhaseList]
      .filter((phase) => {
        return phase.itemDescriptionWithPhasecode?.toLocaleLowerCase()?.includes(this.searchString2?.toLowerCase());
      })
      ?.slice()
      ?.reverse();
  }

  addPhaseToCurrentList(_phase: PhaseDetails): void {
    const phase = { ..._phase };
    phase.worksFor = phase.companyId;
    let check = false;
    this._currentPhaseList?.forEach((element) => {
      if (element.phaseID === phase.phaseID && element.isTANDM === phase.isTANDM) {
        check = true;
      }
    });
    if (!check) {
      this._currentPhaseList.push(phase);
      this.filterCurrentPhases();
    }
  }

  showRemovePhaseFromCurrentListDialog(template: TemplateRef<unknown>, data: PhaseDetails): void {
    this.modalRef = this.modalService.show(template);
    this.phaseToRemove = data;
  }

  removePhaseFromCurrentList(selection: boolean): void {
    if (selection) {
      let index = this.currentPhaseList.findIndex(
        (i) => i?.phaseID === this.phaseToRemove?.phaseID && i?.jobSchedulePhaseForTimeAndMaterialID === this.phaseToRemove?.jobSchedulePhaseForTimeAndMaterialID,
      );
      if (index >= 0) {
        this.currentPhaseList.splice(index, 1);
      }

      index = this._currentPhaseList.findIndex(
        (i) => i?.phaseID === this.phaseToRemove?.phaseID && i?.jobSchedulePhaseForTimeAndMaterialID === this.phaseToRemove?.jobSchedulePhaseForTimeAndMaterialID,
      );
      if (index >= 0) {
        this._currentPhaseList.splice(index, 1);
      }
    }
  }

  closeModal(): void {
    this.sidebarModalService.hideModal();
  }

  updateNoLaborData(event: any) {
    this.noLabor = event.currentTarget.checked;
    this.getPhaseList();
  }
}
