export enum ViewerType {
  VIDEO = 'Video',
  IMAGE = 'Image',
}

export const ThumbnailSize = [
  { label: 'Extra Small', name: 'Extra Small' },
  { label: 'Small', name: 'Small' },
  { label: 'Medium', name: 'Medium' },
  { label: 'Large', name: 'Large' },
];

export enum KeyBoardKey {
  RIGHT_ARROW = 'ArrowRight',
  LEFT_ARROW = 'ArrowLeft',
}

export const ImageExtension = ['jpg', 'jpeg', 'gif', 'png', 'heif', 'heic', 'webp', 'octet-stream'];
