import { Injectable } from '@angular/core';
import { getItem, setItem, StorageItem } from '@core/utils';
import { BehaviorSubject } from 'rxjs';
import { EventTask } from '../models/user.modal';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  selectedEvent = new BehaviorSubject({});
  selectedEventType = this.selectedEvent.asObservable();
  selectedDate = new BehaviorSubject('');
  userSelectedDate = this.selectedDate.asObservable();
  headerTitle = new BehaviorSubject('Dashboard');
  headerTitle$ = this.headerTitle.asObservable();
  userRole = new BehaviorSubject('');
  userRoleDetails$ = this.userRole.asObservable();
  userScheduleEditor = new BehaviorSubject<boolean>(false);
  userScheduleEditor$ = this.userScheduleEditor.asObservable();
  companyId = new BehaviorSubject<number>(0);
  companyId$ = this.companyId.asObservable();
  constructor() {}

  updateEvent(userSelectedEvent: EventTask) {
    this.selectedEvent.next(userSelectedEvent);
    setItem(StorageItem.selectedEventtype, userSelectedEvent);
    setItem(StorageItem.userSelectedJobId, userSelectedEvent.jobScheduleId);
  }

  userGetEvent(): EventTask {
    return getItem(StorageItem.selectedEventtype) as EventTask;
  }

  UpdateDate(userSelectedDate: string) {
    this.selectedDate.next(userSelectedDate);
    setItem(StorageItem.userSelectedDate, userSelectedDate);
  }

  getSelectedDate(): string {
    return getItem(StorageItem.userSelectedDate) as string;
  }

  updateHeaderTitle(headerTitle: string) {
    this.headerTitle.next(headerTitle);
    setItem(StorageItem.headerTitle, headerTitle);
  }

  getHeaderTitle() {
    return getItem(StorageItem.headerTitle);
  }

  updateRoleDetailsData(userRoleCode: string) {
    this.userRole.next(userRoleCode);
    setItem(StorageItem.userRole, userRoleCode);
  }

  updateIsUserAdminData(isScheduleEditor: boolean) {
    this.userScheduleEditor.next(isScheduleEditor);
  }

  updateCompanyId(companyId: number) {
    this.companyId.next(companyId);
  }

  updateSelectedDate(dateobj: Date) {
    setItem(StorageItem.userSelctedDateobj, dateobj);
  }

  getUserRole(): string {
    return getItem(StorageItem.userRole) as string;
  }

  getLoginUser(): string {
    return getItem(StorageItem.userEmail) as string;
  }
}
