import { environment } from '@env/environment';

export enum StorageItem {
  AuthToken = 'authToken',
  RefreshToken = 'refreshToken',
  userEmail = 'UserName',
  selectedEventtype = 'selectedEventType',
  userSelectedJobId = 'userSelectedJobId',
  userSelectedDate = 'userSelectedDate',
  headerTitle = 'headerTitle',
  userProfile = 'userProfile',
  userRole = 'userRole',
  userSelctedDateobj = 'userSelectedDateobj',
  currentSchedularDate = 'currentSchedularDate',
  currentViewtype = 'currentViewtype',
  currentCompany = 'currentCompany',
}

export const getItem = (itemName: StorageItem): unknown | null => {
  const item = getStorage().getItem(itemName);
  return item ? JSON.parse(item) : null;
};

export const setItem = (itemName: StorageItem, value: unknown): void => {
  getStorage().setItem(itemName, JSON.stringify(value));
};

export const removeItem = (itemName: StorageItem): void => {
  getStorage().removeItem(itemName);
};

export const removeALLItem = (): void => {
  getStorage().clear();
};

export const getStorage = () => {
  return environment.persistUserSession ? localStorage : sessionStorage;
};
