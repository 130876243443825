import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const MODULES = [CommonModule, FormsModule, ReactiveFormsModule];

@NgModule({
  providers: [...MODULES],
  exports: [...MODULES],
})
export class SharedLibsModule {}
