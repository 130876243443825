import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AppLoaderService } from '../../services/app-loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppLoaderComponent implements AfterContentChecked {
  constructor(private readonly loaderService: AppLoaderService, private readonly cdr: ChangeDetectorRef) {}

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  get loaderEnabled() {
    return this.loaderService.loaderEnabled;
  }
}
