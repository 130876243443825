<div class="modal-header">
  <p class="modal-title pull-left">{{ title }}</p>
  <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalResponse(false)">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-content></ng-content>
</div>
<div class="modal-footer" *ngIf="showFooter">
  <button class="btn btn-primary ms-2" (click)="modalResponse(true)" [disabled]="!enablePositiveResponse">{{ positiveResponse }}</button>
  <button *ngIf="!hideNegativeResponseBtn" class="btn btn-outline-primary ms-2" (click)="modalResponse(false)">{{ closeButtonName }}</button>
</div>
