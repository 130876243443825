import { Component, OnDestroy } from '@angular/core';
import { MESSAGES, SortDirection } from '@constants/*';
import { Account } from '@pages/auth/models';
import { LazyLoadEvent } from 'primeng/api';
import { Subject } from 'rxjs';
import { Constants } from 'src/app/@shared/constants/app.constants';
import { PaginationConfig } from 'src/app/@shared/models';
import { Utils } from 'src/app/@shared/services/util.service';
import { faIcons } from './font-awesome-icon.utils';
import { ROUTER_UTILS } from './router.utils';

@Component({
  selector: 'app-base',
  template: '',
})
export class BaseComponent implements OnDestroy {
  currentUser!: Account | null;
  destroy$: Subject<void> = new Subject();
  path = ROUTER_UTILS.config;
  constants = Constants;
  faIcons = faIcons;
  messages = MESSAGES;
  utils = Utils;
  pageTitle!: string;
  paginationConfig: PaginationConfig = new PaginationConfig();

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  sort(): string | undefined {
    return this.paginationConfig.predicate ? `${this.paginationConfig.predicate},${this.paginationConfig.ascending ? SortDirection.ASC : SortDirection.DESC}` : undefined;
  }

  sortParams() {
    return {
      page: (this.paginationConfig?.page || 1) - 1,
      size: this.paginationConfig.itemsPerPage,
      sort: this.sort(),
    };
  }

  onSortChange(sortEvent: LazyLoadEvent, callbackFn: Function): void {
    console.log('sort changed - ', sortEvent);
    this.paginationConfig.predicate = sortEvent.sortField as string;
    this.paginationConfig.ascending = sortEvent.sortOrder === 1;
    callbackFn();
  }
}
