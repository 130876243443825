import { NgModule } from '@angular/core';
import { CustomSlicePipe } from './custom-slice.pipe';
import { HighlightPipe } from './highlight.pipe';
import { SafeUrlPipe } from './safeUrl.pipe';

@NgModule({
  declarations: [CustomSlicePipe, HighlightPipe, SafeUrlPipe],
  exports: [CustomSlicePipe, HighlightPipe, SafeUrlPipe],
})
export class PipesModule {}
