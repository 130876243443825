<div class="row">
  <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="0" [(ngModel)]="strExpression" readonly />
</div>

<div class="row mt-5">
  <div class="col-lg-12 col-md-12 col-sm-12">
    <div class="d-flex flex-nowrap justify-content-between">
      <button class="btn btn-outline-primary cal-value d-block" [disabled]="isLayerBtnEnabled" (click)="addExpression(numbers.NUM_1)">1</button>
      <button class="btn btn-outline-primary cal-value d-block" [disabled]="isLayerBtnEnabled" (click)="addExpression(numbers.NUM_2)">2</button>
      <button class="btn btn-outline-primary cal-value d-block" [disabled]="isLayerBtnEnabled" (click)="addExpression(numbers.NUM_3)">3</button>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 pt-3">
    <div class="d-flex flex-nowrap justify-content-between">
      <button class="btn btn-outline-primary cal-value d-block" [disabled]="isLayerBtnEnabled" (click)="addExpression(numbers.NUM_4)">4</button>
      <button class="btn btn-outline-primary cal-value d-block" [disabled]="isLayerBtnEnabled" (click)="addExpression(numbers.NUM_5)">5</button>
      <button class="btn btn-outline-primary cal-value d-block" [disabled]="isLayerBtnEnabled" (click)="addExpression(numbers.NUM_6)">6</button>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 pt-3">
    <div class="d-flex flex-nowrap justify-content-between">
      <button class="btn btn-outline-primary cal-value d-block" [disabled]="isLayerBtnEnabled" (click)="addExpression(numbers.NUM_7)">7</button>
      <button class="btn btn-outline-primary cal-value d-block" [disabled]="isLayerBtnEnabled" (click)="addExpression(numbers.NUM_8)">8</button>
      <button class="btn btn-outline-primary cal-value d-block" [disabled]="isLayerBtnEnabled" (click)="addExpression(numbers.NUM_9)">9</button>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 pt-3">
    <div class="d-flex flex-nowrap justify-content-between">
      <button class="btn btn-outline-primary cal-value d-block dot-button" [disabled]="isLayerBtnEnabled" (click)="addExpression(numbers.DEC)">.</button>
      <button class="btn btn-outline-primary cal-value d-block" [disabled]="isLayerBtnEnabled" (click)="addExpression(numbers.NUM_0)">0</button>
      <button
        class="btn btn-outline-primary cal-value d-block dot-button directive-icon"
        (click)="backspace()"
        [appImageIconSrc]="constants.staticImages.backspaceThinBlack"
        [hoverImgSrc]="constants.staticImages.backspaceThinWhite"
      ></button>
    </div>
  </div>

  <div class="pt-5 float-end text-end">
    <button class="btn btn-outline-primary" (click)="clearExpression()">Clear</button>
    <button class="btn btn-primary ms-2" (click)="finalLoggedHours()">Done</button>
  </div>
</div>
