import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL_UTIL } from '@core/utils';
import { Observable } from 'rxjs';
import { TimecardApiResponse } from '../../models';
import { HttpStatus } from '../../models/add-employee.modal';
import { AddPhaseResponse, PhaseDetails } from '../../models/add-phase.modal';

@Injectable({
  providedIn: 'root',
})
export class AddPhaseService {
  constructor(private readonly httpClient: HttpClient) {}

  getPhaseList(jobScheduleId: string, searchText: string, isConcrete: boolean, isDeliveries: boolean, noLabor: boolean): Observable<AddPhaseResponse> {
    let Url = API_URL_UTIL.foreman.getAllActivePhasesByJobScheduleId;
    let params = new HttpParams().append('JobScheduleId', jobScheduleId).append('searchText', searchText);

    if (isDeliveries) {
      params = params.append('IsConcrete', isConcrete);
      Url = API_URL_UTIL.foreman.deliveryLeftApi;
    } else {
      params = params.append('noLabor', noLabor);
    }

    return this.httpClient.get<AddPhaseResponse>(`${Url}`, { params });
  }

  updatePhase(
    JobScheduleId: string,
    email: string,
    phaseDetails: Array<PhaseDetails>,
    startDate: string,
    noActionForTAndMPhase: boolean,
    isDeliveries: boolean,
    isConcrete: boolean,
  ): Observable<HttpStatus> {
    let Url = API_URL_UTIL.foreman.savePhasesByJobScheduleId;
    let params = new HttpParams();
    params = params.append('JobScheduleId', JobScheduleId);
    params = params.append('LoggedInUser', email);
    params = params.append('Startdate', startDate);
    params = params.append('NoActionForTAndMPhase', noActionForTAndMPhase);
    params = params.append('isDeliveries', isDeliveries);
    // params = params.append('IsConcrete', isConcrete);

    if (isDeliveries) {
      params = params.set('IsConcrete', isConcrete);
      Url = API_URL_UTIL.foreman.deliverySaveApi;
    }

    return this.httpClient.post<HttpStatus>(Url, { phaseDetails }, { params });
  }

  getCurrentPhaseList(JobScheduleId: string, startDate: string, restrictedTandM: boolean, isDeliveries: boolean, isConcrete: boolean): Observable<TimecardApiResponse> {
    let Url = `Foreman/${API_URL_UTIL.foreman.getCrewPhaseDetailsByJobScheduleId}`;
    let params = new HttpParams().set('JobScheduleId', JobScheduleId).set('startDate', `${startDate}`).set('restrictedTandM', restrictedTandM);

    if (isDeliveries) {
      params = params.append('IsConcrete', isConcrete);
      Url = `${API_URL_UTIL.foreman.deliveryRightApi}`;
    }
    return this.httpClient.get<TimecardApiResponse>(Url, { params });
  }
}
