import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedComponentsModule } from '@sharedComponents/shared-components.module';
import { FontAwesomeIconsModule } from '@sharedModules/*';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DirectivesModule } from 'src/app/@shared/directives/directives.module';
import { SharedLibsModule } from 'src/app/@shared/shared-libs.module';
import { HeaderComponent } from './header.component';

@NgModule({
  declarations: [HeaderComponent],
  imports: [SharedLibsModule, RouterModule, BsDropdownModule, DirectivesModule, FontAwesomeIconsModule, SharedComponentsModule],
  exports: [HeaderComponent],
})
export class HeaderModule {}
