import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTER_UTILS } from '@core/utils/router.utils';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';

const routes: Routes = [
  {
    path: `${ROUTER_UTILS.config.navigation.jobScheduleId}`,
    component: NavigationBarComponent,
    children: [
      { path: '', redirectTo: ROUTER_UTILS.config.navigation.timecard, pathMatch: 'full' },
      { path: ROUTER_UTILS.config.navigation.timecard, loadChildren: async () => (await import('./../time-card/time-card.module')).TimeCardModule },
      { path: ROUTER_UTILS.config.navigation.deliveries, loadChildren: async () => (await import('./../deliveries/deliveries.module')).DeliveriesModule },
      { path: ROUTER_UTILS.config.navigation.quantities, loadChildren: async () => (await import('./../quantities/quantities.module')).QuantitiesModule },
      { path: ROUTER_UTILS.config.navigation.hourlyTrucking, loadChildren: async () => (await import('./../hourly-trucking/hourly-trucking.module')).HourlyTruckingModule },
      { path: ROUTER_UTILS.config.navigation.equipments, loadChildren: async () => (await import('./../equipments/equipments.module')).EquipmentsModule },
      { path: ROUTER_UTILS.config.navigation.observations, loadChildren: async () => (await import('./../observations/observations.module')).ObservationsModule },
      { path: ROUTER_UTILS.config.errorResponse.unauthorized, loadChildren: async () => (await import('./../un-authorized/un-authorized.module')).UnAuthorizedModule },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NavigationBarRoutingModule {}
