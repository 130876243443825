import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { BaseComponent } from '@core/utils';
import { takeUntil } from 'rxjs';
import { StatusService } from '../../../@shared/services/status.service';
import { UserDataService } from '../../../@shared/services/user-data.service';
import { JobModuleCompleted, NavigationItem } from '../../dashboard/models/dashboard.model';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent extends BaseComponent implements OnInit, AfterContentChecked {
  userSelectionEvent!: any;
  status!: JobModuleCompleted;
  positionCode!: string;
  navigation: NavigationItem[] = [];

  constructor(private readonly userDataService: UserDataService, private readonly statusService: StatusService) {
    super();
  }

  ngOnInit(): void {
    this.getAndProcessPositionCode();
    this.statusService.status$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (status: JobModuleCompleted) => {
        this.status = status;
      },
    });
  }

  getAndProcessPositionCode(): void {
    this.userDataService.userRoleDetails$.subscribe((userRole) => {
      this.positionCode = userRole;
      switch (this.positionCode) {
        case this.constants.roles.foreman:
          this.navigation = this.foremanNavigation;
          break;

        case this.constants.roles.ShopSupervisor:
          // this.navigation = this.shopManagerNavigation;
          break;

        case this.constants.roles.labour:
        case this.constants.roles.shopAssist:
        case this.constants.roles.mechanic:
        default:
          break;
      }
    });
  }

  get foremanNavigation(): NavigationItem[] {
    return [
      {
        title: 'Time Card',
        route: ['./', this.path.navigation.timecard],
        queryParams: {},
        icon: this.constants.staticImages.timecardIconHover,
        hoverIcon: this.constants.staticImages.timeCardImage,
        isCompleted: false,
        _isCompletedKey: 'isTimecardCompleted',
      },
      {
        title: 'Deliveries',
        route: ['./', this.path.navigation.deliveries],
        queryParams: {},
        icon: this.constants.staticImages.DeliveriesCardImage,
        hoverIcon: this.constants.staticImages.deliveryIconHover,
        isCompleted: this.status?.['isDeliveriesCompleted'],
        _isCompletedKey: 'isDeliveriesCompleted',
      },
      {
        title: 'Hourly Trucking',
        route: ['./', this.path.navigation.hourlyTrucking],
        queryParams: {},
        icon: this.constants.staticImages.HourlyTruckingImage,
        hoverIcon: this.constants.staticImages.HourlyTruckingImageHover,
        isCompleted: this.status?.['isHoulryTruckingCompleted'],
        _isCompletedKey: 'isHoulryTruckingCompleted',
      },
      {
        title: 'Quantities',
        route: ['./', this.path.navigation.quantities],
        queryParams: {},
        icon: this.constants.staticImages.QuantitiesImage,
        hoverIcon: this.constants.staticImages.quantitiesImageHover,
        isCompleted: this.status?.['isQuantitiesCompleted'],
        _isCompletedKey: 'isQuantitiesCompleted',
      },
      {
        title: 'Equipment',
        route: ['./', this.path.navigation.equipments],
        queryParams: {},
        icon: this.constants.staticImages.EquipmentImage,
        hoverIcon: this.constants.staticImages.equipmentImageHover,
        isCompleted: this.status?.['isEquipmentCompleted'],
        _isCompletedKey: 'isEquipmentCompleted',
      },
      {
        title: 'Observations',
        route: ['./', this.path.navigation.observations],
        queryParams: {},
        icon: this.constants.staticImages.ObservationsImage,
        hoverIcon: this.constants.staticImages.ObservationsImageHover,
        isCompleted: this.status?.['isObservationCompleted'],
        _isCompletedKey: 'isObservationCompleted',
      },
    ];
  }

  get shopManagerNavigation(): NavigationItem[] {
    return [
      {
        title: 'Time Card',
        route: ['./', this.path.navigation.timecard],
        queryParams: {},
        icon: this.constants.staticImages.timecardIconHover,
        hoverIcon: this.constants.staticImages.timeCardImage,
        isCompleted: this.status?.['isTimecardCompleted'],
        _isCompletedKey: 'isTimecardCompleted',
      },
    ];
  }

  setPageHeader(): void {
    this.userSelectionEvent = this.userDataService.userGetEvent();
    if (this.userSelectionEvent) {
      this.userDataService.updateHeaderTitle('');
    } else {
      this.userSelectionEvent = this.userDataService.userGetEvent();
    }
  }

  ngAfterContentChecked(): void {
    this.setPageHeader();
  }
}
