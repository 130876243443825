import { Injectable } from '@angular/core';
import { BaseCrudService } from '@core/services';
import { API_URL_UTIL } from '@core/utils';

@Injectable({ providedIn: 'root' })
export class ForemanService extends BaseCrudService {
  getBaseAPIPath(): string {
    return API_URL_UTIL.foreman.root;
  }
}
