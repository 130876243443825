import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { BaseComponent } from '@core/utils';

import { AddEmployeeService } from '@pages/time-card/add-employee/add-employee.service';

import { takeUntil } from 'rxjs';

import { AllCompanies, CompaniesListResponse } from '../../models/add-employee.modal';

@Component({
  selector: 'app-company-list-dropdown',

  templateUrl: './company-list-dropdown.component.html',

  styleUrls: ['./company-list-dropdown.component.scss'],
})
export class CompanyListDropdownComponent extends BaseComponent implements OnInit, OnChanges {
  constructor(private readonly addEmployeeService: AddEmployeeService) {
    super();
  }

  companyList!: Array<AllCompanies>;
  defaultCompany!: AllCompanies | undefined;
  @Input() jobScheduleId!: string;
  @Input() selectedCompany!: number | undefined;
  @Input() companyColor = true;
  @Input() hideAllOptionFromList = false;
  @Output() onChange: EventEmitter<number> = new EventEmitter();
  @Output() companies: EventEmitter<Array<AllCompanies>> = new EventEmitter();

  ngOnInit(): void {
    this.getAllCompanies();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.selectedCompany) {
      this.setSelectedCompany();
    }
  }

  getAllCompanies(): void {
    this.addEmployeeService
      .getAllCompanies()
      .pipe(takeUntil(this.destroy$))
      .subscribe((allCompanies: CompaniesListResponse) => {
        this.companyList = allCompanies.data;
        this.companies.emit(allCompanies.data);
        if (!this.hideAllOptionFromList) {
          this.companyList.splice(0, 0, this.constants.allOptionToDropdown);
        }
        this.setSelectedCompany();
      });
  }

  setSelectedCompany(): void {
    if (this.companyList !== undefined) {
      this.defaultCompany = this.companyList.find((c) => c.companyId === this.selectedCompany);
    }
  }

  onSelect(event: any): void {
    if (event !== undefined) {
      this.onChange.emit(event.companyId);
    }
  }
}
