<section>
  <div class="row" *ngIf="isDetailPage">
    <div class="text-center large-text">
      <h5>Detail page - {{ jobScheduleDetail?.jobDescription }}</h5>
      <hr />
    </div>
  </div>

  <div class="row custom-actions">
    <div>
      <div class="float-end action-btn">
        <p-splitButton label="Actions" [(model)]="dropdownOptions" styleClass="p-button-sm"></p-splitButton>
      </div>
    </div>
  </div>

  <div class="row mt-4 mb-4">
    <div class="col-6">
      <div class="row">
        <div class="col-4 text-heading">Company</div>
        <div class="col-8">{{ jobScheduleDetail?.companyName }}</div>
      </div>
      <div class="row">
        <div class="col-4 text-heading">Job</div>
        <div class="col-8">{{ jobScheduleDetail?.jobDescription }}</div>
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-4 text-heading">Scheduled Date</div>
        <div class="col-8">{{ formatStartDate(jobScheduleDetail?.startDate) }}</div>
      </div>
      <div class="row">
        <div class="col-4 text-heading">Scheduled By</div>
        <div class="col-8">{{ jobScheduleDetail?.createdBy }}</div>
      </div>
    </div>
  </div>

  <div class="card border-2 border-primary mb-3">
    <div class="card-header">
      <div class="text-heading">
        <a class="title" [href]="jobScheduleDetail?.googleUrl" target="_blank " rel="noopener noreferrer "
          ><strong class="text-primary">{{ jobScheduleDetail?.jobDescription }}</strong>
        </a>
        <strong>({{ jobScheduleDetail?.contractId }})</strong>
      </div>
    </div>
    <div class="card-body">
      <div class="card-text" *ngIf="jobScheduleDetail?.projectManagerName || jobScheduleDetail?.estimator">
        <p *ngIf="jobScheduleDetail?.projectManagerName" class="mb-0 text-fs-14">
          PM:<strong> {{ jobScheduleDetail?.projectManagerName }} </strong>
        </p>
        <p *ngIf="jobScheduleDetail?.estimator" class="mb-0 text-fs-14">
          EST:<strong> {{ jobScheduleDetail?.estimator }} </strong>
        </p>

        <p *ngIf="jobScheduleDetail?.isStateFunded" class="mb-0 text-fs-14 mt-2">
          <strong> STATE/FED FUNDED </strong>
        </p>
      </div>
      <br />
      <div class="day-text-heading" *ngIf="jobScheduleDetail?.jobSchedulePhaseViewModelList?.length">Phases</div>
      <div class="phase-block mt-1" *ngFor="let phase of jobScheduleDetail?.jobSchedulePhaseViewModelList">
        <div *ngIf="phase?.phaseId !== '0'">
          <p class="mb-0 text-fs-14">
            <span>{{ phase?.phaseId }}</span>
            <span class="b-text"> {{ phase?.phaseDescription }}</span>
            <span class="b-text" *ngIf="phase?.estLabourProd"> ({{ phase?.estLabourProd }}) </span>
            <span class="mb-0 text-fs-14" *ngIf="phase?.employeesFullName"> {{ phase?.employeesFullName }}</span>
            <br />
          </p>
        </div>
      </div>
      <div class="mt-2" *ngIf="jobScheduleDetail?.assignedWorkForce?.length">
        <div class="day-text-heading">Assigned Workforce (Employee)</div>

        <div class="employee-detail" *ngFor="let employeeDetail of jobScheduleDetail?.assignedWorkForce">
          <div class="card-text employee-block mt-1 text-fs-14">
            <p class="b-text mb-0 text-light-gray">{{ employeeDetail.positionCode }} ({{ employeeDetail.employeeCount }}):</p>
            <ul>
              <li *ngFor="let emp of employeeDetail?.positionWiseEmployees" class="fw-500 ms-4">
                <span [ngClass]="emp?.isDeletedFromForeman ? 'text-decoration-line-through' : ''" [ngStyle]="{ color: emp?.employeeColor }">{{ emp?.employeeName }} </span>
                <strong *ngIf="emp?.noShowDetails">({{ emp?.noShowDetails }})</strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mt-2" *ngIf="jobScheduleDetail?.jobEquipmentsJobnet?.length">
        <div class="day-text-heading">Equipment</div>
        <p class="card-text mb-0 text-fs-14">
          <span class="fw-500 me-2" *ngFor="let equipment of jobScheduleDetail?.jobEquipmentsJobnet; let last = last">
            <span *ngIf="equipment?.equipment"> {{ equipment?.equipment }}</span>
            <span *ngIf="!last">{{ !last ? ', ' : '' }}</span>
          </span>
        </p>
      </div>
      <div class="mt-2" *ngIf="jobScheduleDetail?.jobAddress?.length">
        <div class="card-text day-text-heading" *ngIf="jobScheduleDetail?.jobAddress">Address</div>
        <span
          ><fa-icon [icon]="faIcons.faLocationArrow" class="font-icon mx-1 mt-1 fs-14"> </fa-icon> <span class="mb-0 text-fs-14">{{ jobScheduleDetail?.jobAddress }}</span></span
        >
      </div>
      <div class="mt-2" *ngIf="jobScheduleDetail?.jobScheduleStatusId === 4 || jobScheduleDetail?.rainedOut">
        <div class="card-text day-text-heading mt-3">Status</div>
        <span class="mb-0 text-fs-14 pink-text" *ngIf="jobScheduleDetail?.jobScheduleStatusId === 4">Cancelled</span>
        <p class="mb-0 text-fs-14 pink-text" *ngIf="jobScheduleDetail?.rainedOut">Weather Cancellation</p>
      </div>
      <div class="mt-2" *ngIf="jobScheduleDetail?.notes">
        <div class="day-text-heading">Notes</div>
        <p class="card-text mb-0 text-fs-14 mx-2">
          <span>{{ jobScheduleDetail?.notes }}</span>
        </p>
      </div>
    </div>
  </div>

  <div class="row mt-5 custom-actions" *ngIf="isDetailPage">
    <div>
      <div class="float-end">
        <button class="btn btn-primary mx-3" (click)="closeJobDetail()">Close</button>
      </div>
    </div>
  </div>
</section>

<section *ngIf="!isDetailPage">
  <div class="d-flex flex-wrap mt-1 form-group note-form">
    <div class="w-100">
      <span class="p-float-label me-2">
        <textarea rows="5" pInputTextarea placeholder="Enter your Notes" class="border note-textarea" [(ngModel)]="notes"></textarea>
      </span>
    </div>
  </div>
  <div class="row mt-5 custom-actions">
    <div>
      <div class="float-start">
        <button class="btn btn-primary" (click)="gotoWizard()">Previous</button>
      </div>
      <div class="float-end">
        <app-common-cancel></app-common-cancel>
        <button class="btn btn-primary" (click)="submitJobSchedule()">Save & Close</button>
      </div>
    </div>
  </div>
</section>

<ng-template #confirmationCancelPopup>
  <app-modal [title]="cancelConfirmationPopupTitle" [modalRef]="modalRef" [positiveResponse]="modalButtonName" (onAction)="cancelModalResponse($event)">
    <p class="modal-body">Are you sure you want to {{ cancelConfirmationPopupTitle }}?</p>
  </app-modal>
</ng-template>

<ng-template #deleteConfirmation>
  <app-modal title="Delete Schedule Job" [modalRef]="modalRef" [positiveResponse]="modalButtonName" (onAction)="deleteModalResponse($event)">
    <p class="modal-body">Are you sure you want to delete this job?</p>
  </app-modal>
</ng-template>

<ng-template #openPenel>
  <app-modal [title]="jobScheduleDetail?.jobDescription" [modalRef]="modalRef" [showFooter]="false" (onAction)="closeScheduleCardModal()">
    <div class="schedule-card-popup">
      <div class="row mt-4 mb-4">
        <div class="col-8">
          <div class="position-relative fc-datepicker">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <img [src]="constants.staticImages.icons.calender" alt="image hear" />
                <input
                  type="datepicker"
                  #date
                  class="form-control date-picker"
                  bsDatepicker
                  [bsConfig]="constants.schedularDatePicker"
                  [(bsValue)]="dateToCopy"
                  (bsValueChange)="calenderDateChange($event)"
                  (keydown)="(false)"
                  showTodayButton="true"
                />
              </span>
            </div>
          </div>
        </div>
        <div class="col-4">
          <p-splitButton label="Copy" [(model)]="copyJobDropdownOptions" styleClass="p-button-sm"></p-splitButton>
        </div>
      </div>
    </div>
  </app-modal>
</ng-template>
