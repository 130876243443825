import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseCrudService } from '@core/services';
import { API_URL_UTIL } from '@core/utils';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UploadFilesService extends BaseCrudService {
  getBaseAPIPath(): string {
    return API_URL_UTIL.foreman.root;
  }

  uploadFile<UploadFileApiResponse>(endpoint: string, body: FormData, options?: any): Observable<HttpEvent<UploadFileApiResponse>> {
    return this.httpClient.post<UploadFileApiResponse>(this.getFullAPIUrl(endpoint), body, { ...options });
  }
}
